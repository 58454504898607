import { Grid } from "@material-ui/core";
import React from "react";
import "./Review.css";
import { Link } from "react-router-dom";

export default function Review() {
  var items = [
    {
      id: "card1",
      name: "Ashraf Usman Ali",
      img: "Images/Australia.png",
      role: "Australia",
      review:
        "May Allah Subhan Wa Ta'ala bestow His blessing by the Waseela of His Habib Sallallahu Alaihi Wasallam on this Academy and May all our Dua be with this good and excellant work.",
    },
    {
      id: "card2",
      name: "Imtiaz S.Jussab",
      img: "Images/Malawi.png",
      role: "Malawi",
      review:
        "Masha-Allah! A very beutiful of modern Islamic centre.May Allah fulfill Hazrat Akhtar Raza Sahab's dream to benefit all Muslims. ",
    },
    {
      id: "card3",
      name: "Prof.M.Halim Khan ",
      img: "Images/India.png",
      role: "Member N.M.C.M.E, MHRD Govt of India",
      review:
        "    Jamiatur Raza and its buildings, maintanance and record keeping of the Institution is like a regural school ,it is role model for Madarsa Education as well as Religious Education.Students are coming under main stream of Education.    ",
    },
    {
      id: "card4",
      name: "Mohamed Salim",
      img: "Images/Singapore.png",
      role: "Singapore",
      review:
        "Alham-du-lillah i was quite impressed with the management curriculum and devotion of staff and students. Incoroperation of Technologies was really Impressive subject allowing over future Ulemas be well conversant with newest media technologies.Being in touch with the ....  ",
    },
  ];
  return (
    <div>
      <div className="chooseUsHeader">
        <p>REVIEWS FROM OUR CLIENTS</p>
        <p>What People Say</p>
      </div>

      <Grid container spacing={0}>
        {items.map((item, i) => (
          <ReviewGrid key={i} item={item} />
        ))}
      </Grid>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "-70px",
          marginBottom: "40px",
        }}
      >
        <div>
          <Link style={{ decoration: "none", color: "white" }} to="/reviews">
            <button className="serviceCompoBtn ReviewBtn">
              See All Reviews
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

function ReviewGrid(props) {
  return (
    // <Grid container  spacing={0}>

    // <Grid item sm={2} ></Grid>
    <Grid
      className="d-flex justify-content-center"
      item
      xs={12}
      sm={6}
      md={6}
      lg={3}
    >
      <div className="reviewCard" id={props.item.id}>
        <div
          style={{ display: "flex", marginTop: "20px", alignItems: "center" }}
        >
          <div style={{ flexBasis: "10%" }}></div>
          <div style={{ flexBasis: "40%" }}>
            <img src={props.item.img} alt="clientReview" />
          </div>
          <div style={{ flexBasis: "50%", marginLeft: "-25px" }}>
            <p className="ClientName">{props.item.name}</p>
            <p className="ClientRole">{props.item.role}</p>
          </div>
        </div>
        <p className="ReviewTxt">{props.item.review}</p>
      </div>
    </Grid>
    // </Grid>
  );
}
