import React from 'react'
import '../Career_Page/Career.css'
import Breadcrumb2 from '../BreadCrumb/BreadCrumb'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';


function Career() {
    return (
        <>
                        {/* <Breadcrumb2 pagename="Career"/> */}
                        <nav className="bread-crumbs">
				<div>
					<div className="row">
						<div className="col-12">
							<ul className="bread-crumbs-list ">
								<li>
									<a href="index.html" className="hoverNavHead">Home</a>
									{/* &nbsp;&gt;&nbsp; */}
									</li>

                <ChevronRightIcon className="material-icons md-18" />
								<li className=""><a href="" className="hoverNavHead">Career</a></li>
							</ul>
						</div>
					</div>
				</div>
			</nav>
            <div className="main-content pt-5">
            
                    <div className="row">
                <div className="col-12 " style={{ padding: '0px 5vw' }}>
                    <div className="content">

                        <p>
                            You might be glad to hear that <strong>Imam Ahmad Raza Trust</strong> is forming its own IT Workforce for digital transformation and has multiple opportunities for dynamic Software Engineers. You will work extensively working as <strong>full stack developer</strong> with latest technology <strong>React, React Native, .NET Core Services </strong>with <strong>.NET EF Core Framework </strong>and <strong>SQL Azure</strong> to build <strong>progressive web </strong>and <strong>hybrid mobile app development.</strong>
                    </p>
                        <p>
                            We have many highly qualified and experienced <strong>volunteers </strong>from top MNC like <strong>Microsoft, IBM, etc.</strong> to help and guide for digital transformation. If you are passionate about software engineering, excited by constantly learning in an ever-changing environment and market –we want to hear from you!</p>
                    </div>
                    <div className="bg row">
                        <h2>Software Engineer (No of Positions: 2)</h2>
                    </div>
                    <div className="row">
                        <h2 >Responsibilities:</h2>
                        <div className="responsibility row p-2">
                        <ul>
                            <li>Design, build, test and support software to bring the cutting-edge technologies into Azure.</li>
                            <li>Align with Agile Development Process with continuous delivery.
                            
</li>
                        </ul>
                        </div>
                    </div>
                    <div className="row">
                        <h2>Required Qualifications:</h2>
                        <div className="required row">
                            <ul>
                                <li>0 - 2 years of professional software engineering experience
</li>
                                <li>Highly proficient with C/C++/C#/JAVA
</li>
                                <li>Bachelor’s degree or higher in Computer Science or related field, OR equivalent industry experience</li>
                            </ul>

                        </div>
                    </div>
                    <div className="row pt-5">
                        <h2>Location: Bareilly Shareef</h2>
                        <div className="row">
                         <h2>Extra Benefits: Stay and food</h2>
                        </div>
                    </div>
                    <div className="bg row mt-5">
                        
                    <h2>Internship (No of Positions: 3)</h2>
                    </div>
           <div className="row">
             <p>We are also hiring for 6-month internship program to give an opportunity to work on a live project which will be targeting more than 1L+ users with 24/7 availability.</p>
            </div>
            <div className="row">
            <div className="row">
                        <h2>Required Qualifications:</h2>
                        <div className="required row">
                            <ul>
                                <li>0 - 2 years of professional software engineering experience
</li>
                                <li>Highly proficient with C/C++/C#/JAVA
</li>
                                <li>Bachelor’s degree or higher in Computer Science or related field, OR equivalent industry experience</li>
                            </ul>

                        </div>
                    </div>
            </div>
            <div className="row">
            <div className="row pt-5">
                        <h2>Location: Bareilly Shareef</h2>
                        <div className="row">
                         <h2>Extra Benefits: Stay and food</h2>
                        </div>
                    </div>
            </div>
            <div className="row">
                <p>***Note: This opening is only for Sunni Sahiul Aqida Muslim</p>
                    </div>
            <div className="row">
                <p>You can send your resume to <strong style={{color:"#4895ef"}}>hr@imamahmadrazatrust.org</strong> for further processing</p>
                    </div>
            </div>
                </div>
            </div>

            
        </>
    )
}

export default Career
