import React, { Component } from 'react'
import '../Home_Page/Services/Services'
import '../Donation/Donation.css'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Accordion from 'react-bootstrap/Accordion'
import { BsFillCaretRightFill } from 'react-icons/bs'
import { Breadcrumb } from 'react-bootstrap'
import Breadcrumb2 from '../BreadCrumb/BreadCrumb'
import { IoChevronDownCircle } from "react-icons/io5";

import ChevronRightIcon from '@material-ui/icons/ChevronRight';

// import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
//import ChevronRightIcon from '@material-ui/icons/ChevronRight';
//import Settings from '@material-ui/icons/Settings';
//import { Grid } from '@material-ui/core';

export default function Services() {
    return (
        <>
            {/* <Breadcrumb2 pagename="Donation" /> */}
            <nav className="bread-crumbs">
				<div>
					<div className="row">
						<div className="col-12">
							<ul className="bread-crumbs-list ">
								<li>
									<a href="index.html" className="hoverNavHead">Home</a>
									{/* &nbsp;&gt;&nbsp; */}
									</li>
									<ChevronRightIcon className="material-icons md-18" />
								<li className=""><a href="Donation" className="hoverNavHead">Donation</a></li>
							</ul>
						</div>
					</div>
				</div>
			</nav>
            <div className="container-fluid howtodonate">

                <h2>HOW TO DONATE</h2>
                <p className="pl-5 pr-5">You can donate your Zakaat, Sadqaat, Atiyat using any of below options.</p>

            </div>
            <div className='container-fluid'>
                {/* <h2 style={{ textAlign: "center" }}>To transfer your money to the bank account of the Trust </h2> */}
                <div className="IndianDonators pt-5">

                    <div >
                        <button className="carouselBtn2" style={{ fontWeight: "1000px" }}>
                            FOR INDIAN DONORS
                        {/* <IoChevronDownCircle/> */}
                        </button>

                    </div>
                </div>
                <div className="IndianDonators row justify-content-xl-center p-md-5 ">

                    <div className="col-xl-3 col-md-6 pt-5  ">
                        <CardComponent img="/Images/SBIlogo.png" name="State Bank of India" acnumber="30078123009" ifsccode="SBIN0000597 " width="50px" branchaddress="Kutubkhana Branch, Bareilly - 243001."/>
                    </div>
                    <div className="col-xl-3 col-md-6 pt-5  ">
                        <CardComponent img="/Images/hdfcbank.jpg" name="HDFC Bank Ltd." acnumber="50200004721350" ifsccode="HDFC0000304 
                        " width="50px" branchaddress="154, Civil Lines, Bareilly - 243001."/>
                    </div>
                    {/* <div className="col-xl-3 col-lg-6 pt-5  ">
                        <CardComponent img="/Images/upilogo.png" name="UPI" acnumber="Jamiaturraza@SBI"
                            ifsccode=" SBIN0000597 " width="90px" />
                    </div> */}
                    {/* <div className="col-md-6 pt-5  ">
                        <CardComponent name="Abroad" />
                    </div>
                    <div className="col-md-6 pt-5  ">
                        <CardComponent name="UPI" />
                    </div> */}
                    <div className="col-xl-3 col-md-6 pt-5  ">
                        <div>
                            <Card style={{ borderRadius: '20px', boxShadow: '0px 0px 6px 0px #888888', width: "" }}>
                                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                                <Card.Body >
                                    <Card.Title style={{ fontSize: '2rem' }}>
                                        <div className="IndianDonators pb-2">

                                            <h1 className="pb-0"><img style={{ width: "100px", height: "50px" }}
                                                src="Images/upilogo.png"
                                                className="deptImg"
                                                alt="alt"
                                            /></h1>
                                            {/* <p>To send money online, visit the Donation Section of the website <strong> <a href="https://www.jamiaturraza.com/" target="/blank ">www.jamiaturraza.com</a> </strong> of the Institute. </p> */}
                                            {/* <h5 style={{ fontSize: '1.5rem' }}></h5> */}
                                            {/* <h2><strong>To transfer your money to the bank account of the Trust </strong></h2> */}
                                        </div></Card.Title>
                                    <Card.Text style={{ fontSize: '3rem' }}>

                                        <div className="IndianDonators pb-3">
                                            <div className="container-fluid pb-0">
                                                <div className="row justify-content-center d-block">
                                                    {/* <p>To send money online, visit the Donation Section of the website <strong> <a href="https://www.jamiaturraza.com/" target="/blank ">www.jamiaturraza.com</a> </strong> of the Institute. </p> */}

                                                    <p className="bankfont pb-lg-0 pb-md-5">
                                                            <strong style={{color:"black"}}>UPI Id : </strong>Jamiaturraza@SBI</p>
                                               
                                                            <img style={{ height: "30px", width: "80px",marginBottom:"18px" }} src="Images/gpaylogo.png" alt="" />  <br />
                                            
                                                            <img style={{ height: "30px", width: "100px" ,marginBottom:"18px" }} src="Images/amazonpaylogo.png" alt="" /><br />
                                                             
                                                        
                                                            <img style={{ height: "30px", width: "80px" ,marginBottom:"18px" }} src="Images/paytmlogo.png" alt="" />
                                                             <br />
                                                        
                                                           <p> <img style={{ height: "35px", width: "35px"}} src="Images/phonepelogo.png" alt="" />PhonePe </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Text>
                                    {/* <Button variant="primary">Go somewhere</Button> */}
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>

            </div>
            <div className="container-fluid p-5">
                <div className="row pl-5">
                    <p>You can also donation online through <strong> <a href="https://www.jamiaturraza.com/donations/" target="/blank ">www.jamiaturraza.com</a> </strong> of the Institute. <br /></p>
                    <div className="row">
                    <h1 > <strong>50% Tax exemption under section 80G</strong></h1>
                    <p>If you Donate to Imam Ahmad Raza Trust, you are allowed to 50% deduct the donation amount under section 80G of the Income Tax Act 1961 vide registration no. F-CIT.BLY/IART 82-SG, BLY/56/41/2011-12, Pan No. is AATI4095D</p>
                    </div>

                    {/* <p>Pay by <img style={{height:"37px",width:"80px"}}src="Images/upilogo.png" alt="" /> UPI <strong>“Jamiaturraza@SBI”</strong>  From  <img style={{height:"30px",width:"80px"}}src="Images/gpaylogo.png" alt="" /> Google pay, <img style={{height:"30px",width:"100px"}}src="Images/amazonpaylogo.png" alt="" />Amazon Pay, <img style={{height:"30px",width:"80px"}}src="Images/paytmlogo.png" alt="" /> PayTm,<img style={{height:"30px",width:"35px"}}src="Images/phonepelogo.png" alt="" /> Phone pay etc

Alternatively, you can reach the Jamia, deposit the donation amount, and get the receipt.</p> */}
                </div>
            </div>
            {/* <HideShowBar /> */}
            <Accordion2 />
        </>
    )
}

// Accordion Start **
function Accordion2() {
    return (
        <div>

            <Accordion className="pb-5" >
                <Card className="">
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="0">

                            <div className="row donationcomponent">
                                <div className="">

                                    <h1 style={{ color: 'rgb(76, 122, 230)', fontWeight: '500' }}> <BsFillCaretRightFill /> Why You Assist Imam Ahmad Raza Trust</h1>
                                </div>
                                {/* <div className="col" style={{fontSize:'12px',color:'black'}}>
                                  Click Here to Read More
                                </div> */}
                            </div>

                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <div  className=" ">
                            {/* <div style={{height:"250px"}}className=" overflow-auto"> */}
                            <div className="donationcomponent">
                                <h3 className="pb-3">Qaziyul-Quzzaat Fil Hind Hazrat Allama Mufti</h3>
                                <h2 className="pb-3">Muhammad Akhtar Raza Khan</h2>

                                <p>Rahmatullahi Ta’ala Barkatuhu</p>
                            </div>
                            <div>
                                <p>
                                    <p className="pl-5">My advice to Ahle Sunnat wa Jamaa’at in general, and to those
                        associated with the Qādiriyyah Barakātiyyah Razaviyyah Silsila in particular:</p>
                                    <ul>
                                        <li> Maslak e Ahle Sunnat wa Jamaa’at that is called Maslak e Aala
                                        Hazrat for identification, be firmly steadfast on it, keep yourselves
                                        away from Bad Mazhabs especially Rafzis, Qadiyanis, Wahhabies,
                        Devbandis, and Sulah Kullis, consider their company and intermingling with them fatal poison.</li>
                                        <li> Be regular and firm in fasts, Salaah(Namaaz), and various virtuous deeds yourselves, and also try to make others regular and firm.</li>
                                        <li> Keep yourselves away from evil deeds, and try to keep others away from evil deeds.</li>
                                        <li> Demonstrate good behavior always, at every place, and in every task.</li>
                                        <li>Participate more and more in good deeds.</li>
                                        <li>Help the poor and their children in education, moral upbringing, and marriage in every possible way.</li>
                                        <li>Do not forget helping Deeni Madaaris (Madrasas) while giving your
                                        Sadqa, Zakaat, and donations. Particularly, give special attention to
                                        the great educational institute of Markaz e Ahle Sunnat, Bareilly
                        Shareef - Jamiatur Raza. From time to time, keep helping it and play an important role in helping it complete its ambitions.</li> Establish great Madrasas and libraries in your localities, appoint good
firm Sunni scholars in them, make decent arrangements for their
accommodation, livelihood and other necessities, and then stay in
contact with them and obtain their guidance in every deeni(religious)
matter.
I have hope that you will both act on my advice yourselves, and
advise others to act on this. I pray to Mawlaa Ta’aalaa that he
bestows upon us the grace to serve Real Islam more and more,
protects our religion and faith, and causes our end to be on it only.
Aameen Bajahe Saiyyadul Mursaleen Salwatullahi Ta’ala Alaihi Ajmaeen..</ul>
                                </p>
                            </div>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="1">
                            <div className="ourspecialities">
                                <h1 style={{ color: 'rgb(76, 122, 230)', fontWeight: '500' }}><BsFillCaretRightFill /> Our Specialities</h1>
                            </div>
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body><div className="ourspecialities">
                            {/* <h3 >OUR SPECIALITIES</h3> */}
                            <p>
                                <ul>
                                    <li>Guardianship, regular advice and preaching of Huzoor Taajush Shari'ah (Rahmatullahi Alaih )</li>

                                    <li>Only limited number of students get admission in each class so that the decorum of the class remains maintained.</li>

                                    <li>Education and upbringing under the watch of expert teachers of each field from the beginning of itself. Stress on high
                                    attendance and low absence of students in the class.
                                    Stress on high attendance of students, and low absence, in Class.
								</li>
                                    <li>Spending day and night in the Jamia, from Fajr to 11 in the night, according to the Schedule only.</li>
                                    <li>After Maghrib namaaz, obligatory attendance of students in class for Arabic conversation for one hour.</li>

                                    <li>For students of higher classes, arrangement for courses on English speaking, and CCC; compulsory attendance of
                                    students in weekly sessions for cultural activities.
</li>

                                    <li>Bedding system facilities for the students residing in hostel.</li>

                                    <li>Healthy diet is necessary for the health of body and mind. Therefore, there is an excellent arrangement for edibles in the
                                    Jamia, in which students are given food according to a menu all the three times of a day.
</li>

                                    <li>R.O. Plant to keep the students safe from diseases caused by drinking water.</li>

                                </ul>
                            </p>
                        </div></Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="2">
                            <div className="futureplans">
                                <h1 style={{ color: 'rgb(76, 122, 230)', fontWeight: '500' }}><BsFillCaretRightFill /> Our Future Plans</h1>
                            </div>
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body><div className="futureplans">
                            {/* <h1>OUR FUTURE PLANS</h1> */}
                            <p>Imam Ahmad Raza Trust has plans to construct the following buildings in the future. Currently, the construction of Hamidi Masjid is going on with great force-</p>
                            <p>
                                <ul>
                                    <li>Expansion of Academic building</li>
                                    <li>Hujjatul Islam Dining Hall</li>
                                    <li>Permanent building for Taajush Shariah Library and Mufassir e Aazam Library</li>
                                    <li>Permanent building for Darut Tajweed wat Tehfeez </li>
                                    <li>Permanent adminstrative building</li>
                                    <li>Separate Building For IT Department</li>
                                    <li>Jilani Guest House</li>
                                    <li>Allaama Naqi Ali Commercial Complex</li>
                                    <li>Permanent building for Department of Tehqeeq and Iftaa</li>

                                </ul>

                            </p>
                        </div></Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    )
}
// Accordion End **

function CardComponent(props) {
    return (
        <div>
            <Card style={{ borderRadius: '20px', boxShadow: '0px 0px 6px 0px #888888', width: "" }}>
                {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                <Card.Body >
                    <Card.Title style={{ fontSize: '2rem' }}>
                        <div className="IndianDonators pb-5">

                            <h1 className=""><img style={{ width: props.width, height: "50px" }}
                                src={window.location.origin + props.img}
                                className="deptImg"
                                alt="alt"
                            /> {props.name}</h1>

                            <p style={{ fontSize: '1.5rem' }}></p>
                            {/* <h2><strong>To transfer your money to the bank account of the Trust </strong></h2> */}
                        </div></Card.Title>
                    <Card.Text style={{ fontSize: '3rem' }}>

                        <div className="IndianDonators pb-5">
                            <p className="bankfont"> <strong style={{ color: "black" }}>Account Name:</strong> Imam Ahmad Raza Trust <br />
                            <strong style={{ color: "black" }}> A/C No. :</strong>  {props.acnumber}
                                <br /><strong style={{ color: "black" }}>IFSC :</strong> {props.ifsccode} <br /> {props.branchaddress}</p>
                        </div>
                    </Card.Text>
                    {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
            </Card>
        </div>
    )
}

