import React from "react";
import "./Service.css";
//import Settings from '@material-ui/icons/Settings';
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

export default function Services() {
  var items = [
    {
      head: "Center of Islamic Studies Jamiatur Raza",
      img: "/Images/NewJamiaLogo.png",
      route: "/CenterOfIslamicStudiesJamiaturRaza",
      content:
        "Impressed by continuous service to religion and community,the Indian subcontinent unified to proclaim loudly the slogan of “Markaz e Ahle Sunnat Bareilly Shareef”. Because in the veins of that Markaz, ‘Imam Ahmad Raza Khan’s’ knowledge and wisdom runs as blood",
    },
    {
      head: "Sharee Council of India",
      img: "/Images/NewSCILogo.png",
      route: "/ShareeCouncilOfIndia",
      content:
        "Sharee Council Of India was established by Huzoor Tajushsharia Allama Mufti Muhammad Akhtar Raza Khan Azhari Rahimahullahu Ta’ala for providing the solutions of burning topics of society, which violate the Islamic Shari'ah",
    },
    // {
    //     head: 'Monthly Sunni Duniya',
    //     img: '/Images/Monthly.jpg',
    //     content: 'The Monthly Sunni Duniya is an example of religious Journalism.This Magazine represents the real voice of Markaze Ahle Sunnat.This wide spread magazine is continue since two decades.'
    // },
    // {
    //     head: 'Markazi Darul Ifta ',
    //     img: '/Images/NewMDILogo.jpg',
    //     content: 'The Markazi Darul Ifta is a pillar in Islamic Jurispudence since the era of Aala Hazrat Imam Ahmad Raza Khan and following thier way of deleivering verdict.This Darul Ifta sentencing their decisions on every topic which is related to Islamic beliefs and Sharia. '
    // },
    {
      head: "Jamat Raza-e Mustafa ",
      img: "/Images/NewJRMLogo.png",
      route: "/JamatRazaeMustafa",
      content:
        "Jamat Raza-e Mustafa was established in 1920 by Aala Hazrat Imam Ahmad Raza Khan.This Jamat is working under the spritual guidance of Imam Ahmad Raza Khan, Huzoor Mufti-e-Aazam-e-Hind and Huzoor Taajush shari'ah. At present, Allama Mufti Muhammad Asjad Raza Khan Qadri Hafidahullahu Ta'ala / ظفح اہلل اعتٰ is leading the Jamat.",
    },
  ];
  return (
    // <div className="serviceComponent d-flex flex-column justify-content-center col-12">
    <div className="serviceComponent d-flex flex-column justify-content-center col-12">
      <p style={{ fontSize: "1.6rem" }}>AREAS WHAT WE SERVE</p>
      <p>OUR DEPARTMENT'S</p>
      <Grid container spacing={0}>
        {items.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </Grid>

      <div
        className="d-flex justify-content-center"
        style={{ marginTop: "40px", marginBottom: "40px" }}
      >
        <Link style={{ decoration: "none", color: "white" }} to="/department">
          <button className="serviceCompoBtn">See all departments</button>
        </Link>
      </div>
    </div>
  );
}

function Item(props) {
  return (
    <Grid
      className="d-flex justify-content-center"
      item
      xs={12}
      sm={12}
      md={6}
      lg={6}
      xl={4}
    >
      <Link
        style={{ decoration: "none" }}
        className="link"
        to={props.item.route}
      >
        <div className="serviceCard">
          <img
            src={window.location.origin + props.item.img}
            className="deptImg"
            alt="alt"
          />
          <div>
            <h3 style={{color:'black'}} className="link">{props.item.head}</h3>
            <p className="serviceCompoCardTxt link">{props.item.content}</p>
          </div>
        </div>
      </Link>
    </Grid>
  );
}
