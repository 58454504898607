import React from 'react'
import './Department.css'
// import Breadcrumb from 'react-bootstrap/BreadcrumbItem'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Breadcrumb2 from '../BreadCrumb/BreadCrumb'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
//import Settings from '@material-ui/icons/Settings';
//import { Grid } from '@material-ui/core';

export default function Services() {
    return (
        <> 
        <Breadcrumb2 pagename="Centre For Book Compilation and Publishing"/>
        <div className="departmentComponent">
               <p>AREAS WHAT WE SERVE</p>
               <p> Centre For Book Compilation</p>
          <p><img src="/Images/uc3.png" alt=""/></p>
        </div>
        </>
    )
}

