import React from "react";
import "./Experience.css";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingBottom: "3rem",
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: '800px'
  },
}));

export default function Experience() {
  const classes = useStyles();
  let expDetials = [
    { title: "2K+", content: "Students" },
    { title: "12+", content: "Departments" },
    { title: "100+", content: "Employers" },
  ];
  return (
    <div className="experienceHead">
      <div className={classes.root} spacing={0}>
        <div>
          <div className="numberCircle">
            <div className="numberCircle1">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <p className="expNum">20</p>
                <p style={{ marginTop: "30px" }} className="expTxt">
                  Years <br /> of Excellence
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="smallCircleHead">
            <div className="numberCircle2"></div>
            <div style={{ marginLeft: "10px", marginTop: "30px" }}>
              <div className="numberCircle3"></div>
            </div>
          </div>
        </div>
        <div>
          {expDetials.map((item, i) => (
            <div className="expDetails" key={i}>
              <p className="expTitle">{item.title}</p>
              <p className="expContent">{item.content}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
