import React from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import "./Header.css";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { Popover } from "react-tiny-popover";
import DehazeIcon from "@material-ui/icons/Dehaze";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
// import'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
    width: "120px",
  },
}));

export default function Header() {
  // return (
  //   <div>
  //     <div className="defaultHeader">
  //       <DefaultHeader />
  //     </div>
  //     <div className="mobileHeader">
  //       <MobileHeader />
  //     </div>
  //     {/* <div className="Navbar">
  //       <Nav />
  //     </div> */}

  //   </div>
  // )
  return (
    <>
      {/* <div style={{width: '100vw', height: '50px'}}></div> */}
      <Navbar collapseOnSelect expand="xl" bg="light">
        <Navbar.Brand href="/home">
          <img
            src={window.location.origin + "/Images/NewIARTLogo1.png"}
            alt="logo"
            className="imgHome"
          />
          <span className="homeLogoTxt">Imam Ahmad Raza Trust</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{ overflowY: 'auto' }} />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav>
            <Nav.Link as={Link} className="headerContent" to="/home">
              Home
            </Nav.Link>
            <Nav.Link as={Link} className="headerContent" to="/about">
              About Us
            </Nav.Link>
            <NavDropdown
              title="Departments"
              id="collasible-nav-dropdown"
              className="headerContent"
            >
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/CentreForIslamicJurisprudence">
                Centre for Islamic Jurisprudence
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/CentreForJudicialVerdict">
                Centre for Judicial verdict
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/CenterOfIslamicStudiesJamiaturRaza">
                Center of Islamic Studies Jamiatur Raza
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/ShareeCouncilOfIndia">
                Sharee Council of India
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/JamatRazaeMustafa">
                Jamat Raza-e-Mustafa
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/MonthlySunniDuniya">
                Monthly Sunni Duniya
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/CentreOfResearchAndTraining">
                Centre of Research and Training
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/DepartmentOfReligiousJournalism">
                Department of Religious Journalism
              </NavDropdown.Item>
              {/* <NavDropdown.Item as={Link} to="/DepartmentOfPrintingAndPublishing">
              Department of Printing and Publishing
            </NavDropdown.Item> */}
              {/* <NavDropdown.Item as={Link} to="/MarkaziDarulIfta">
              Markazi Darul Ifta
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/MarkaziDarulQaza">
              Markazi Darul Qaza
            </NavDropdown.Item> */}
              <NavDropdown.Item as={Link} to="/CentreForBookCompilation">
                Centre for Book compilation and Publishing
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/MarkaziMoonSightingCommittee">
                Markazi Moon Sighting Committee
              </NavDropdown.Item>
              <NavDropdown.Divider />
            </NavDropdown>
            <Nav.Link as={Link} className="headerContent" to="/Donation">
              Donation
            </Nav.Link>
            <Nav.Link as={Link} className="headerContent" to="/books">
              Books
            </Nav.Link>
            <Nav.Link as={Link} className="headerContent" to="/gallery">
              Gallery
            </Nav.Link>
            <Nav.Link as={Link} className="headerContent" to="/Career">
              Career
            </Nav.Link>
            <Nav.Link as={Link} className="headerContent" to="/ContactUs">
              Contact Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

function PageMenu() {
  return (
    <div style={{ display: "flex" }}>
      {/* Features */}
      <div
        style={{
          flexBasis: "38%",
          marginRight: "20px",
          borderRight: "1px solid lightgrey",
          paddingBottom: "40px",
        }}
      >
        <p className="pageHead">Features</p>
        <div style={{ display: "flex" }}>
          <div style={{ flexBasis: "50%" }}>
            <div className="pageContent">
              <span>
                <Link to="/UIElements">UI Elements</Link>
              </span>
              <span className="mnu-label">hot</span>
            </div>
            <div className="pageContent">Header/Footer Min..</div>
            <div className="pageContent">Footer Light</div>
            <div className="pageContent">Footer Widget Dark</div>
          </div>
          <div style={{ flexBasis: "50%" }}>
            <div className="pageContent">Header/Footer Def...</div>
            <div className="pageContent">Header/Footer Cen...</div>
            <div className="pageContent">Footer Minimal Light</div>
            <div className="pageContent">Footer Widget Light</div>
          </div>
        </div>
      </div>
      {/* Features */}
      {/* Additional Pages */}
      <div
        style={{ flexBasis: "40%", paddingLeft: "20px", paddingBottom: "40px" }}
      >
        <p className="pageHead">Additional Pages</p>
        <div style={{ display: "flex" }}>
          <div style={{ flexBasis: "50%" }}>
            <div className="pageContent">
              <span>Coming Soon</span>
            </div>

            <div className="pageContent">Pricing</div>

            <div className="pageContent">
              Single Project
              <span className="mnu-label">New</span>
            </div>

            <div className="pageContent">Testimonials</div>

            <div className="pageContent">Privacy Policy</div>
            <div className="pageContent">Terms and Conditions</div>

            <div className="pageContent">404 Page</div>
          </div>
          <div style={{ flexBasis: "50%" }}>
            <div className="pageContent">Our Team</div>
            <div className="pageContent">Our Team 2</div>
            <div className="pageContent">FAQ</div>
            <div className="pageContent">Search Results</div>

            <div className="pageContent">Brands</div>

            <div className="pageContent">Forms</div>

            <div className="pageContent">
              404 Page 2<span className="mnu-label">New</span>
            </div>
          </div>
        </div>
      </div>
      {/* Additional Pages */}
      {/* Slider Image */}
      <div style={{ flexBasis: "22%" }}>
        <img
          src={window.origin + "/Images/mnu-banner.jpg"}
          style={{ width: "300px", height: "320px" }}
          className="pageMenuImg"
        />
      </div>
      {/* Slider Image */}
    </div>
  );
}

function DefaultHeader() {
  let flag = true;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const hoverChange = () => {
    flag = false;
  };

  const open = Boolean(anchorEl);

  let navBarContent = ["Home", "About Us"];

  //Popover Elements
  let [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  let [isPopoverOpenDept, setIsPopoverOpenDept] = React.useState(false);
  let [isPagePopoverOpen, setIsPagePopoverOpen] = React.useState(false);
  let [isBlogPopoverOpen, setIsBlogPopoverOpen] = React.useState(false);
  let [isGalleryPopoverOpen, setIsGalleryPopoverOpen] = React.useState(false);

  let [isContactPopoverOpen, setIsContactPopoverOpen] = React.useState(false);

  const homePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
    setIsPopoverOpenDept(false);
    setIsPagePopoverOpen(false);
    setIsBlogPopoverOpen(false);
    setIsGalleryPopoverOpen(false);
    setIsContactPopoverOpen(false);
  };
  const departPopover = () => {
    setIsPopoverOpenDept(!isPopoverOpenDept);
    setIsPopoverOpen(false);
    setIsPagePopoverOpen(false);
    setIsBlogPopoverOpen(false);
    setIsGalleryPopoverOpen(false);
    setIsContactPopoverOpen(false);
  };

  const pagePopOver = () => {
    setIsPagePopoverOpen(!isPagePopoverOpen);
    setIsPopoverOpenDept(false);
    setIsPopoverOpen(false);
    setIsBlogPopoverOpen(false);
    setIsGalleryPopoverOpen(false);
    setIsContactPopoverOpen(false);
  };

  const blogPopOver = () => {
    setIsBlogPopoverOpen(!isBlogPopoverOpen);
    setIsPagePopoverOpen(false);
    setIsPopoverOpenDept(false);
    setIsPopoverOpen(false);
    setIsGalleryPopoverOpen(false);
    setIsContactPopoverOpen(false);
  };
  const GalleryPopOver = () => {
    setIsGalleryPopoverOpen(!isGalleryPopoverOpen);
    setIsBlogPopoverOpen(false);
    setIsPagePopoverOpen(false);
    setIsPopoverOpenDept(false);
    setIsPopoverOpen(false);
    setIsContactPopoverOpen(false);
  };

  const ContactPopOver = () => {
    setIsContactPopoverOpen(!isContactPopoverOpen);
    setIsGalleryPopoverOpen(false);
    setIsBlogPopoverOpen(false);
    setIsPagePopoverOpen(false);
    setIsPopoverOpenDept(false);
    setIsPopoverOpen(false);
  };
  //Popover Elements
  return (
    <div className="navBar">
      <div className="headerLogoContainer">
        <a className="homeLogoContainer" href="/">
          <img
            src={window.location.origin + "/Images/logo.jpg"}
            alt="logo"
            className="imgHome"
          />
          <span className="homeLogoTxt">Imam Ahmad Raza Trust</span>
        </a>
      </div>
      <div className="headerAlign">
        <div style={{ display: "flex" }}>
          <div className="headerSplit1">
            <div style={{ display: "flex" }}>
              <div className="headerContent">
                {/* <span onMouseOver={hoverChange}component={Link} to="/about">  onClick={()=>{ this.props.history.push("/about")}} */}
                <Link
                  style={{ color: "black", textDecoration: "none" }}
                  className="headerHover"
                  onMouseOver={hoverChange}
                  to="/Home"
                >
                  Home
                </Link>
              </div>
              <div className="headerContent">
                {/* <span onMouseOver={hoverChange}component={Link} to="/about">  onClick={()=>{ this.props.history.push("/about")}}*/}
                <Link
                  style={{ color: "black", textDecoration: "none" }}
                  className="headerHover"
                  onMouseOver={hoverChange}
                  to="/about"
                >
                  About US
                </Link>
              </div>
              <div
                className="headerContent"
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
              >
                <Popover
                  isOpen={isPopoverOpenDept}
                  positions={["bottom"]} // preferred positions by priority
                  content={
                    <div
                      className="popOverStyle"
                      id="deptPop"
                      onMouseLeave={() => setIsPopoverOpenDept(false)}
                    >
                      <div className="popOverContentStyle1">
                        <Link
                          style={{ color: "black", textDecoration: "none" }}
                          className="headerHover"
                          onMouseOver={hoverChange}
                          to="/CentreForBookCompilation"
                        >
                          Centre for Book compilation
                        </Link>
                      </div>
                      <div className="popOverContentStyle1">
                        <Link
                          style={{ color: "black", textDecoration: "none" }}
                          className="headerHover"
                          onMouseOver={hoverChange}
                          to="/CentreForIslamicJurisprudence"
                        >
                          Centre for Islamic Jurisprudence
                        </Link>
                      </div>

                      <div className="popOverContentStyle1">
                        <Link
                          style={{ color: "black", textDecoration: "none" }}
                          className="headerHover"
                          onMouseOver={hoverChange}
                          to="/CentreForJudicialVerdict"
                        >
                          Centre for Judicial verdict
                        </Link>
                      </div>
                      <div className="popOverContentStyle1">
                        <Link
                          style={{ color: "black", textDecoration: "none" }}
                          className="headerHover"
                          onMouseOver={hoverChange}
                          to="/CenterOfIslamicStudiesJamiaturRaza"
                        >
                          Center of Islamic Studies Jamiatur Raza
                        </Link>
                      </div>
                      <div className="popOverContentStyle1">
                        <Link
                          style={{ color: "black", textDecoration: "none" }}
                          className="headerHover"
                          onMouseOver={hoverChange}
                          to="/CentreOfResearchAndTraining"
                        >
                          Centre of Research and Training
                        </Link>
                      </div>
                      <div className="popOverContentStyle1">
                        <Link
                          style={{ color: "black", textDecoration: "none" }}
                          className="headerHover"
                          onMouseOver={hoverChange}
                          to="/DepartmentOfPrintingAndPublishing"
                        >
                          Department of Printing and Publishing
                        </Link>
                      </div>
                      <div className="popOverContentStyle1">
                        <Link
                          style={{ color: "black", textDecoration: "none" }}
                          className="headerHover"
                          onMouseOver={hoverChange}
                          to="/DepartmentOfReligiousJournalism"
                        >
                          Department of Religious Journalism
                        </Link>
                      </div>
                      <div className="popOverContentStyle1">
                        <Link
                          style={{ color: "black", textDecoration: "none" }}
                          className="headerHover"
                          onMouseOver={hoverChange}
                          to="/MarkaziDarulIfta"
                        >
                          Markazi Darul Ifta
                        </Link>
                      </div>
                      <div className="popOverContentStyle1">
                        <Link
                          style={{ color: "black", textDecoration: "none" }}
                          className="headerHover"
                          onMouseOver={hoverChange}
                          to="/MarkaziDarulQaza"
                        >
                          Markazi Darul Qaza
                        </Link>
                      </div>
                      <div className="popOverContentStyle1">
                        <Link
                          style={{ color: "black", textDecoration: "none" }}
                          className="headerHover"
                          onMouseOver={hoverChange}
                          to="/MarkaziMoonSightingCommittee"
                        >
                          Markazi Moon Sighting Committee
                        </Link>
                      </div>
                      <div className="popOverContentStyle1">
                        <Link
                          style={{ color: "black", textDecoration: "none" }}
                          className="headerHover"
                          onMouseOver={hoverChange}
                          to="/MonthlySunniDuniya"
                        >
                          Monthly Sunni Duniya
                        </Link>
                      </div>
                      <div className="popOverContentStyle1">
                        <Link
                          style={{ color: "black", textDecoration: "none" }}
                          className="headerHover"
                          onMouseOver={hoverChange}
                          to="/JamatRazaeMustafa"
                        >
                          Jamat Raza-e-Mustafa
                        </Link>
                      </div>
                      <div className="popOverContentStyle1">
                        <Link
                          style={{ color: "black", textDecoration: "none" }}
                          className="headerHover"
                          onMouseOver={hoverChange}
                          to="ShareeCouncilOfIndia"
                        >
                          Sharee Council of India
                        </Link>
                      </div>
                    </div>
                  }
                >
                  <div onMouseEnter={departPopover}>
                    <span onMouseOver={hoverChange}>
                      <div style={{ display: "flex" }}>
                        <div style={{ flexBasis: "50%" }}>
                          <Link
                            style={{ color: "black", textDecoration: "none" }}
                            className="headerHover"
                            onMouseOver={hoverChange}
                            to="/department"
                          >
                            Departments
                          </Link>
                        </div>
                        <div style={{ flexBasis: "50%", marginTop: "4px" }}>
                          {flag ? (
                            <ExpandMoreIcon className="headerExpIcon" />
                          ) : (
                            "hi"
                          )}
                        </div>
                      </div>
                    </span>
                  </div>
                </Popover>
              </div>
            </div>
          </div>
          <div className="headerSplit2">
            <div style={{ display: "flex" }}>
              {/* Page Menu */}
              {/* <div className="headerContent">
                <Popover
                  isOpen={isPagePopoverOpen}
                  positions={['bottom']} // preferred positions by priority
                  content={
                    <div className="pagePopOverStyle"
                      id="pagePop"
                      onMouseLeave={() => setIsPagePopoverOpen(false)}>
                      <PageMenu />
                    </div>}>
                  <div onMouseEnter={pagePopOver}>
                    <span onMouseOver={hoverChange}>
                      <div style={{ display: 'flex' }}>
                        <div style={{ flexBasis: '50%' }}>
                          Pages</div>
                        <div style={{ flexBasis: '50%', marginTop: '4px' }}>
                          {flag ? <ExpandMoreIcon className="headerExpIcon" /> : 'hi'}
                        </div>
                      </div>
                    </span>
                  </div>
                </Popover>
              </div> */}
              {/* Page Menu */}
              {/* Blog Menu */}
              <div
                className="headerContent"
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
              >
                {/* <Popover
                  isOpen={isBlogPopoverOpen}
                  positions={['bottom']} // preferred positions by priority
                  content={
                    <div className="popOverStyle" id="homePop"
                      onMouseLeave={() => setIsBlogPopoverOpen(false)}
                    >
                      <div className="popOverContentStyle1">
                        <Link style={{ color: 'black', textDecoration: 'none' }}
                          className="headerHover"
                          onMouseOver={hoverChange} to="/GridBlog">
                          Grid Blog
              </Link>

                      </div>
                      <div className="popOverContentStyle">
                        List Blog
        </div>

                      <div className="popOverContentStyle">
                        Blog Post
        </div>
                    </div>

                  }
                >
                  <div onMouseEnter={blogPopOver}>
                    <span onMouseOver={hoverChange}>
                      <div style={{ display: 'flex' }}>
                        <div style={{ flexBasis: '50%' }}>
                          Donation
            </div>
                        <div style={{ flexBasis: '50%', marginTop: '4px' }}>
                          {flag ? <ExpandMoreIcon className="headerExpIcon" /> : 'hi'}
                        </div>
                      </div>
                    </span>
                  </div>
                </Popover> */}
                {/* <div style={{ flexBasis: '50%' }}>
                        Donation  
            </div> */}
                <div className="headerContent">
                  {/* <span onMouseOver={hoverChange}component={Link} to="/about">  onClick={()=>{ this.props.history.push("/about")}}*/}
                  <Link
                    style={{ color: "black", textDecoration: "none" }}
                    className="headerHover"
                    onMouseOver={hoverChange}
                    to="/Donation"
                  >
                    Donation
                  </Link>
                </div>
              </div>
              {/* Blog Menu */}'{/* Gallery Menu */}
              <div
                className="headerContent"
                aria-owns={open ? "mouse-over-popover" : undefined}
                aria-haspopup="true"
              >
                <Popover
                  isOpen={isGalleryPopoverOpen}
                  positions={["bottom"]} // preferred positions by priority
                  content={
                    <div
                      className="popOverStyle"
                      id="homePop"
                      onMouseLeave={() => setIsGalleryPopoverOpen(false)}
                    >
                      <div className="popOverContentStyle1">
                        Grid Padding Gallery
                      </div>
                      <div className="popOverContentStyle">
                        Grid No Padding Gallery
                      </div>
                      <div className="popOverContentStyle">Grid Masonry</div>
                    </div>
                  }
                >
                  <div onMouseEnter={GalleryPopOver}>
                    <span onMouseOver={hoverChange}>
                      <div style={{ display: "flex" }}>
                        <div style={{ flexBasis: "50%" }}>Gallery</div>
                        <div style={{ flexBasis: "50%", marginTop: "4px" }}>
                          {flag ? (
                            <ExpandMoreIcon className="headerExpIcon" />
                          ) : (
                            "hi"
                          )}
                        </div>
                      </div>
                    </span>
                  </div>
                </Popover>
              </div>
              {/* Gallery Menu */}
            </div>
          </div>

          <div
            className="headerSplit3"
            style={{ marginLeft: "-120px !important" }}
          >
            <div style={{ display: "flex" }}>
              <div className="headerContent">
                <Link
                  style={{ color: "black", textDecoration: "none" }}
                  className="headerHover"
                  onMouseOver={hoverChange}
                  to="/ContactUs"
                >
                  Contact Us
                </Link>
              </div>

              <div className="headerContent" style={{ paddingLeft: "30px" }}>
                <span onMouseOver={hoverChange}>EN</span>
              </div>

              <div className="headerContent">
                <span onMouseOver={hoverChange}>
                  <SearchIcon />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function MobileHeader() {
  let flag = true;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const hoverChange = () => {
    flag = false;
  };

  const open = Boolean(anchorEl);
  //Popover Elements
  let [isMenuPopoverOpen, setIsMenuPopoverOpen] = React.useState(false);

  const menuPopover = () => {
    setIsMenuPopoverOpen(!isMenuPopoverOpen);
  };
  //Popover Elements

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className="mobileHeaderStart">
      <div style={{ flexBasis: "10%" }}></div>
      {/* MobileMenu */}
      <div style={{ flexBasis: "25%" }}>
        <Popover
          isOpen={isMenuPopoverOpen}
          positions={["bottom"]} // preferred positions by priority
          content={
            <div className="mobilePopOverStyle">
              {/* Home   */}
              <Accordion
                className="MenuAccor"
                id="menuAcc"
                style={{ border: "1px solid grey", lineHeight: "48px" }}
                expanded={expanded === "panel4"}
                onClick={() => {
                  console.log(expanded);
                  // if (expanded == "panel4") {
                  //   document.getElementById('panel4bh-header').style.background = "#F0F4F8"
                  //   document.getElementById('menuAcc').style.marginBottom = "0px"
                  //   document.getElementById('panel3bh-header').style.background = "none"
                  //   document.getElementById('panel5bh-header').style.background = "none"
                  // }
                  // else {
                  //   document.getElementById('menuAcc').style.marginBottom = "-10px"
                  //   document.getElementById('panel4bh-header').style.background = "blue"
                  //   document.getElementById('panel3bh-header').style.background = "none"
                  //   document.getElementById('panel5bh-header').style.background = "none"

                  // }

                  setIsMenuPopoverOpen(false);
                }}
              >
                <AccordionSummary
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  {/* <Typography className="menuBox">Home</Typography> */}
                  <Link
                    style={{
                      color: "black",
                      textDecoration: "none",
                      lineHeight: "48px",
                      width: "100%",
                    }}
                    className="headerHover"
                    onMouseOver={hoverChange}
                    to="/home"
                  >
                    Home
                  </Link>
                </AccordionSummary>

                {/* <AccordionDetails className="subMenuBox">
                  <div>
                    Home 1
                  </div>
                </AccordionDetails>

                <AccordionDetails className="subMenuBox">
                  <div>
                    Home 2
                  </div>
                </AccordionDetails>

                <AccordionDetails className="subMenuBox">
                  <div>
                    Home 3
                  </div>
                </AccordionDetails> */}
              </Accordion>
              {/* Home   */}
              {/* About Us */}
              <Accordion
                className="MenuAccor"
                id="menuAcc2"
                style={{ border: "1px solid grey" }}
                expanded={expanded === "panel3"}
                onClick={() => {
                  console.log(expanded);
                  setIsMenuPopoverOpen(false);
                }}
              >
                <AccordionSummary
                  // expandIcon={<ExpandMoreIcon className="menuBox" />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  {/* <Typography className="menuBox" style={{ paddingTop: '-50px' }}>About Us</Typography> */}
                  <Link
                    style={{
                      color: "black",
                      textDecoration: "none",
                      lineHeight: "48px",
                      width: "100%",
                    }}
                    className="headerHover"
                    onMouseOver={hoverChange}
                    to="/about"
                  >
                    About Us
                  </Link>
                </AccordionSummary>
              </Accordion>
              {/* About Us */}

              {/* Service Menu */}
              <Accordion
                className="MenuAccor"
                id="sevriceAcc"
                style={{ border: "1px solid grey" }}
                expanded={expanded === "panel5"}
                onClick={() => {
                  console.log(expanded);
                }}
                onChange={handleChange("panel5")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="menuBox" />}
                  aria-controls="panel5bh-content"
                  id="panel5bh-header"
                >
                  <Link
                    style={{
                      color: "black",
                      textDecoration: "none",
                      width: "25%",
                      lineHeight: "48px",
                    }}
                    className="headerHover"
                    onClick={() => {
                      setIsMenuPopoverOpen(false);
                      setExpanded(false);
                    }}
                    onMouseOver={hoverChange}
                    to="/department"
                  >
                    Departments
                  </Link>
                  {/* <Typography className="menuBox">Departments</Typography> */}
                </AccordionSummary>

                <AccordionDetails className="subMenuBox">
                  <Link
                    style={{
                      color: "black",
                      textDecoration: "none",
                      lineHeight: "48px",
                      width: "100%",
                    }}
                    className="headerHover"
                    onClick={() => {
                      setIsMenuPopoverOpen(false);
                      setExpanded(false);
                    }}
                    onMouseOver={hoverChange}
                    to="/CentreForBookCompilation"
                  >
                    Centre for Book compilation
                  </Link>
                </AccordionDetails>

                <AccordionDetails className="subMenuBox">
                  <Link
                    style={{
                      color: "black",
                      textDecoration: "none",
                      lineHeight: "48px",
                      width: "100%",
                    }}
                    className="headerHover"
                    onClick={() => {
                      setIsMenuPopoverOpen(false);
                      setExpanded(false);
                    }}
                    onMouseOver={hoverChange}
                    to="/CentreForIslamicJurisprudence"
                  >
                    Centre for Islamic Jurisprudence
                  </Link>
                </AccordionDetails>

                <AccordionDetails className="subMenuBox">
                  <Link
                    style={{
                      color: "black",
                      textDecoration: "none",
                      lineHeight: "48px",
                      width: "100%",
                    }}
                    className="headerHover"
                    onClick={() => {
                      setIsMenuPopoverOpen(false);
                      setExpanded(false);
                    }}
                    onMouseOver={hoverChange}
                    to="/CentreForJudicialVerdict"
                  >
                    Centre for Judicial verdict
                  </Link>
                </AccordionDetails>
                <AccordionDetails className="subMenuBox">
                  <Link
                    style={{
                      color: "black",
                      textDecoration: "none",
                      lineHeight: "48px",
                      width: "100%",
                    }}
                    className="headerHover"
                    onClick={() => {
                      setIsMenuPopoverOpen(false);
                      setExpanded(false);
                    }}
                    onMouseOver={hoverChange}
                    to="/CenterOfIslamicStudiesJamiaturRaza"
                  >
                    Center of Islamic Studies Jamiatur Raza
                  </Link>
                </AccordionDetails>
                <AccordionDetails className="subMenuBox">
                  <Link
                    style={{
                      color: "black",
                      textDecoration: "none",
                      lineHeight: "48px",
                      width: "100%",
                    }}
                    className="headerHover"
                    onClick={() => {
                      setIsMenuPopoverOpen(false);
                      setExpanded(false);
                    }}
                    onMouseOver={hoverChange}
                    to="/CentreOfResearchAndTraining"
                  >
                    Centre of Research and Training
                  </Link>
                </AccordionDetails>
                <AccordionDetails className="subMenuBox">
                  <Link
                    style={{
                      color: "black",
                      textDecoration: "none",
                      lineHeight: "48px",
                      width: "100%",
                    }}
                    className="headerHover"
                    onClick={() => {
                      setIsMenuPopoverOpen(false);
                      setExpanded(false);
                    }}
                    onMouseOver={hoverChange}
                    to="/DepartmentOfPrintingAndPublishing"
                  >
                    Department of Printing and Publishing
                  </Link>
                </AccordionDetails>
                <AccordionDetails className="subMenuBox">
                  <Link
                    style={{
                      color: "black",
                      textDecoration: "none",
                      lineHeight: "48px",
                      width: "100%",
                    }}
                    className="headerHover"
                    onClick={() => {
                      setIsMenuPopoverOpen(false);
                      setExpanded(false);
                    }}
                    onMouseOver={hoverChange}
                    to="/DepartmentOfReligiousJournalism"
                  >
                    Department of Religious Journalism
                  </Link>
                </AccordionDetails>
                <AccordionDetails className="subMenuBox">
                  <Link
                    style={{
                      color: "black",
                      textDecoration: "none",
                      lineHeight: "48px",
                      width: "100%",
                    }}
                    className="headerHover"
                    onClick={() => {
                      setIsMenuPopoverOpen(false);
                      setExpanded(false);
                    }}
                    onMouseOver={hoverChange}
                    to="/MarkaziDarulIfta"
                  >
                    Markazi Darul Ifta
                  </Link>
                </AccordionDetails>
                <AccordionDetails className="subMenuBox">
                  <Link
                    style={{
                      color: "black",
                      textDecoration: "none",
                      lineHeight: "48px",
                      width: "100%",
                    }}
                    className="headerHover"
                    onClick={() => {
                      setIsMenuPopoverOpen(false);
                      setExpanded(false);
                    }}
                    onMouseOver={hoverChange}
                    to="/MarkaziDarulQaza"
                  >
                    Markazi Darul Qaza
                  </Link>
                </AccordionDetails>
                <AccordionDetails className="subMenuBox">
                  <Link
                    style={{
                      color: "black",
                      textDecoration: "none",
                      lineHeight: "48px",
                      width: "100%",
                    }}
                    className="headerHover"
                    onClick={() => {
                      setIsMenuPopoverOpen(false);
                      setExpanded(false);
                    }}
                    onMouseOver={hoverChange}
                    to="/MarkaziMoonSightingCommittee"
                  >
                    Markazi Moon Sighting Committee
                  </Link>
                </AccordionDetails>
                <AccordionDetails className="subMenuBox">
                  <Link
                    style={{
                      color: "black",
                      textDecoration: "none",
                      lineHeight: "48px",
                      width: "100%",
                    }}
                    className="headerHover"
                    onClick={() => {
                      setIsMenuPopoverOpen(false);
                      setExpanded(false);
                    }}
                    onMouseOver={hoverChange}
                    to="/MonthlySunniDuniya"
                  >
                    Monthly Sunni Duniya
                  </Link>
                </AccordionDetails>
                <AccordionDetails className="subMenuBox">
                  <Link
                    style={{
                      color: "black",
                      textDecoration: "none",
                      lineHeight: "48px",
                      width: "100%",
                    }}
                    className="headerHover"
                    onClick={() => {
                      setIsMenuPopoverOpen(false);
                      setExpanded(false);
                    }}
                    onMouseOver={hoverChange}
                    to="/JamatRazaeMustafa"
                  >
                    Jamat Raza-e-Mustafa
                  </Link>
                </AccordionDetails>
                <AccordionDetails className="subMenuBox">
                  <Link
                    style={{
                      color: "black",
                      textDecoration: "none",
                      lineHeight: "48px",
                      width: "100%",
                    }}
                    className="headerHover"
                    onClick={() => {
                      setIsMenuPopoverOpen(false);
                      setExpanded(false);
                    }}
                    onMouseOver={hoverChange}
                    to="/ShareeCouncilOfIndia"
                  >
                    Sharee Council of India
                  </Link>
                </AccordionDetails>
              </Accordion>
              {/* Service Menu */}
              {/* Donation Menu */}
              <Accordion
                className="MenuAccor"
                id="menuAcc2"
                style={{ border: "1px solid grey" }}
                expanded={expanded === "panel10"}
                onClick={() => {
                  console.log(expanded);
                  setIsMenuPopoverOpen(false);
                }}
              >
                <AccordionSummary
                  // expandIcon={<ExpandMoreIcon className="menuBox" />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  {/* <Typography className="menuBox" style={{ paddingTop: '-50px' }}>About Us</Typography> */}
                  <Link
                    style={{
                      color: "black",
                      textDecoration: "none",
                      lineHeight: "48px",
                      width: "100%",
                    }}
                    className="headerHover"
                    onMouseOver={hoverChange}
                    to="/donation"
                  >
                    Donation
                  </Link>
                </AccordionSummary>
              </Accordion>

              {/* Donation Menu */}
              {/* Books Menu */}
              <Accordion
                className="MenuAccor"
                id="menuAcc2"
                style={{ border: "1px solid grey" }}
                expanded={expanded === "panel10"}
                onClick={() => {
                  console.log(expanded);
                  setIsMenuPopoverOpen(false);
                }}
              >
                <AccordionSummary
                  // expandIcon={<ExpandMoreIcon className="menuBox" />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  {/* <Typography className="menuBox" style={{ paddingTop: '-50px' }}>About Us</Typography> */}
                  <Link
                    style={{
                      color: "black",
                      textDecoration: "none",
                      lineHeight: "48px",
                      width: "100%",
                    }}
                    className="headerHover"
                    onMouseOver={hoverChange}
                    to="/books"
                  >
                    Books
                  </Link>
                </AccordionSummary>
              </Accordion>

              {/* Books Menu */}
              {/* Gallery Menu */}
              <Accordion
                className="MenuAccor"
                id="GalleryAcc"
                style={{ border: "1px solid grey" }}
                expanded={expanded === "panel6"}
                onClick={() => {
                  console.log(expanded);
                }}
                onChange={handleChange("panel6")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="menuBox" />}
                  aria-controls="panel6bh-content"
                  id="panel6bh-header"
                >
                  <Typography
                    style={{ lineHeight: "48px", width: "100%" }}
                    className="menuBox"
                  >
                    Gallery
                  </Typography>
                </AccordionSummary>

                <AccordionDetails className="subMenuBox">
                  <div style={{ lineHeight: "48px", width: "100%" }}>
                    Grid Padding Gallery
                  </div>
                </AccordionDetails>

                <AccordionDetails className="subMenuBox">
                  <div style={{ lineHeight: "48px", width: "100%" }}>
                    Grid No Padding Gallery
                  </div>
                </AccordionDetails>

                <AccordionDetails className="subMenuBox">
                  <div style={{ lineHeight: "48px", width: "100%" }}>
                    Grid Masonry
                  </div>
                </AccordionDetails>
              </Accordion>
              {/* Gallery Menu */}

              {/* blog menu */}
              <Accordion
                className="MenuAccor"
                id="blogAcc"
                style={{ border: "1px solid grey" }}
                expanded={expanded === "panel7"}
                onClick={() => {
                  console.log(expanded);
                  // if (expanded == "panel7") {
                  //   document.getElementById('panel7bh-header').style.background = "#F0F4F8"
                  //   document.getElementById('blogAcc').style.marginTop = "0px"
                  //   document.getElementById('panel3bh-header').style.background = "none"
                  //   document.getElementById('panel4bh-header').style.background = "none"
                  // }
                  // else {
                  //   document.getElementById('blogAcc').style.marginTop = "-15px"
                  //   document.getElementById('panel7bh-header').style.background = "blue"
                  //   document.getElementById('panel3bh-header').style.background = "none"
                  //   document.getElementById('panel4bh-header').style.background = "none"

                  // }
                }}
                onChange={handleChange("panel7")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="menuBox" />}
                  aria-controls="panel7bh-content"
                  id="panel7bh-header"
                >
                  <Typography
                    style={{ lineHeight: "48px", width: "100%" }}
                    className="menuBox"
                  >
                    Blog
                  </Typography>
                </AccordionSummary>

                <AccordionDetails className="subMenuBox">
                  <div style={{ lineHeight: "48px", width: "100%" }}>
                    List Blog
                  </div>
                </AccordionDetails>

                <AccordionDetails className="subMenuBox">
                  <div style={{ lineHeight: "48px", width: "100%" }}>
                    Grid Blog
                  </div>
                </AccordionDetails>

                <AccordionDetails className="subMenuBox">
                  <div style={{ lineHeight: "48px", width: "100%" }}>
                    Grid Blog 2
                  </div>
                </AccordionDetails>
              </Accordion>

              {/* blog menu */}

              {/* contact menu */}

              <Accordion
                className="MenuAccor"
                id="menuAcc2"
                style={{ border: "1px solid grey" }}
                expanded={expanded === "panel8"}
                onClick={() => {
                  console.log(expanded);
                  // if (expanded == "panel3") {
                  //   document.getElementById('panel3bh-header').style.background = "#F0F4F8"
                  //   document.getElementById('menuAcc2').style.marginBottom = "0px"
                  //   document.getElementById('menuAcc2').style.marginTop = "-10px"
                  //   document.getElementById('panel4bh-header').style.background = "none"
                  // }
                  // else {
                  //   document.getElementById('menuAcc2').style.marginBottom = "0px"
                  //   document.getElementById('panel3bh-header').style.background = "blue"
                  //   document.getElementById('panel4bh-header').style.background = "none"
                  //   document.getElementById('panel5bh-header').style.background = "none"
                  // }
                  setIsMenuPopoverOpen(false);
                }}
              >
                <AccordionSummary
                  // expandIcon={<ExpandMoreIcon className="menuBox" />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  {/* <Typography className="menuBox" style={{ paddingTop: '-50px' }}>About Us</Typography> */}
                  <Link
                    style={{
                      color: "black",
                      textDecoration: "none",
                      lineHeight: "48px",
                      width: "100%",
                    }}
                    className="headerHover"
                    onMouseOver={hoverChange}
                    to="/ContactUs"
                  >
                    Contact Us
                  </Link>
                </AccordionSummary>
              </Accordion>
              {/* contact menu */}
            </div>
          }
        >
          <div onClick={menuPopover}>
            <span>
              <DehazeIcon style={{ fontSize: "30px", cursor: "pointer" }} />
            </span>
          </div>
        </Popover>
      </div>
      {/* MobileMenu */}

      <div style={{ flexBasis: "45%" }}>
        <div className="headerLogoContainer">
          <a className="homeLogoContainer" href="/">
            <img
              src={window.location.origin + "/Images/logo.jpg"}
              alt="logo"
              className="imgHome"
            />
            <span className="homeLogoTxt">Imam Ahmad Raza Trust</span>
          </a>
        </div>
      </div>

      <div style={{ flexBasis: "5%", cursor: "pointer" }}>
        <SearchIcon style={{ fontSize: "30px" }} />
      </div>

      <div style={{ flexBasis: "5%", cursor: "pointer" }}>
        <MoreVertIcon style={{ fontSize: "30px" }} />
      </div>

      <div style={{ flexBasis: "10%" }}></div>
    </div>
  );
}
//  Navbar
// const Navigation = () => {
//   return (
//       <>
//           <Navbar className="Navbar" collapseOnSelect fixed='top' expand='sm' bg='dark' variant='dark'>
//             <Container>
//                   <Navbar.Toggle aria-controls='responsive-navbar-nav' />
//                   <Navbar.Collapse id='responsive-navbar-nav' >
//                       <Nav>
//                           <Nav.link href='/'>Home</Nav.link>
//                           <Nav.link href='/about'>About Us</Nav.link>
//                           <Nav.link href='/Department'>Department</Nav.link>
//                           <Nav.link href='/ContactUs'>Contact US</Nav.link>
//                   </Nav>
//               </Navbar.Collapse>
//               </Container>
//       </Navbar>
//   </>
//   );

// }
// export default Navbar;

// ReactNavbar
// export default class Navbars extends Component {
//   state = {
//       toggle:false
//   }
//   Toggle = () => {
//       this.setState({toggle:!this.state.toggle})
//   }
//   render() {
//       return (
//         <>
//             <div className="navBar">
//                   <button onClick={this.Toggle}>
//                       <FaAlignRight />
//                   </button>
//                   <ul className={this.state.toggle ? "nav-links show-nav" : "nav-links"}>
//                       <li href="#">Home</li>
//                       <li href="#">About us</li>
//                       <li href="#">Contact</li>
//                   </ul>
//             </div>
//         </>
//       );
//   }
// }
