import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footerBg pt-5">
      {/* <div style={{ display: "flex", paddingTop: "120px" }}> */}
      <div className="row p-sm-0 p-4">
        {/* <div style={{ flexBasis: "14%", marginLeft: "40px" }}></div>
        <div style={{ flexBasis: "20%" }}> */}
        <div className="col-0 col-sm-1" />
        <div className="col-12 col-xl-3">
          {/* <div style={{ display: "flex" }}> */}
          <div className="row">
            {/* <div style={{ flex: "15%" }}> */}
            <div className="col-sm-2 col-3" style={{ paddingLeft: '0px' }}>
              <img
                src={window.origin + "/Images/NewIARTLogo1.png"}
                alt="logo"
                width="50px"
              />
            </div>
            {/* <div style={{ flex: "5%" }}></div> */}
            {/* <div style={{ flex: "60%" }}> */}
            <div className="col-sm-10 col-9">
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  paddingTop: "5px",
                }}
              >
                Imam Ahmad Raza Trust
              </p>
            </div>
            {/* <div style={{ flex: "20%" }}></div> */}
          </div>

          <p
            className="mt-5"
            style={{
              lineHeight: "30px",
            }}
          >
            Imam Ahmad Raza Trust is non-profit and non-political organization,
            works for the social and spiritual upliftment of the society.
            This organization works in several fields like education, Islamic
            Journalism, Islamic Jurisprudence.
          </p>
        </div>
        {/* <div style={{ flexBasis: "15%", marginLeft: "80px" }}> */}
        <div className="mt-4 mt-sm-0 col-6 col-xl-2">
          <p
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              paddingTop: "5px",
            }}
          >
            Menu
          </p>
          <p>About Us</p>
          <p>Services</p>
          <p>Pricing</p>
          <p>Blog</p>
          <p>Contact Us</p>
        </div>
        {/* <div style={{ flexBasis: "15%", marginLeft: "20px" }}> */}
        <div className="mt-4 mt-sm-0 col-6 col-xl-2">
          <p
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              paddingTop: "5px",
            }}
          >
            Our Departments
          </p>
          <p>Jamiatur Raza</p>
          <p>Jamat Raza-e-Mustafa</p>
          <p>Shar'ee Council of India</p>
          <p>Markazi Darul Ifta</p>
          <p>Monthly Sunni Duniya</p>
        </div>
        {/* <div style={{ flexBasis: "30%", marginLeft: "20px" }}> */}
        <div className="mt-4 mt-sm-0 col-12 col-lg-4">
          <p
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              paddingTop: "5px",
            }}
          >
            Contact Us
          </p>
          <p className="d-flex">
            <img
              src={window.origin + "/Images/location.png"}
              alt="fb"
              width="27px"
              height="27px"
              style={{ paddingLeft: "5px", marginTop: '4px' }}
            />
            82, Dargah-e Aala Hazrat,Mohalla Saudagaran,Bareilly Sharif, Uttar Pradesh, India, POSTAL CODE - 243003
          </p>
          <p className="d-flex">
            <img
              src={window.origin + "/Images/mail.png"}
              alt="fb"
              width="25px"
              height="25px"
              style={{ paddingLeft: "5px", marginRight: "4px", marginTop: '4px' }}
            />
            ContactUs@ImamAhmadRazaTrust.org
          </p>
          <p className="d-flex">
            <img
              src={window.origin + "/Images/Sphone.png"}
              alt="fb"
              width="27px"
              height="27px"
              style={{ paddingLeft: "5px", marginTop: '4px' }}
            />
            +91 9897007120 , +91 581 2458543
          </p>
          {/* <p style={{
                        fontSize:'18px',
                        fontWeight:'bold',
                        paddingTop:'5px'
                    }}>Subcribe</p> */}
          {/* <input className="footerInp"  placeholder="Your Email"/> */}
          {/* <button className="footerBtn">Subcribe</button> */}
        </div>

        {/* <div style={{ flexBasis: "5%" }}></div> */}
      </div>
      <div className="row pl-2 pl-md-0">
        <div className="col-0 col-lg-1" />
        <div className="col-12 col-lg-11 mt-2 mt-sm-4 pl-sm-2">
          <a
            href="https://www.facebook.com/ImamAhmadRazaTrust/"
            target="_blank"
          >
            <img
              src={window.origin + "/Images/fb.png"}
              alt="fb"
              width="35px"
              height="35px"
              style={{ paddingLeft: "5px" }}
            />{" "}
          </a>
          <a
            href="https://www.instagram.com/imamahmadrazatrust/"
            target="_blank"
          >
            <img
              src={window.origin + "/Images/insta.png"}
              alt="fb"
              width="35px"
              height="35px"
              style={{ paddingLeft: "5px", marginLeft: "10px" }}
            />{" "}
          </a>
          <a
            href="https://www.linkedin.com/company/71700623/admin/"
            target="_blank"
          >
            <img
              src={window.origin + "/Images/Linkedin.png"}
              alt="fb"
              width="35px"
              height="35px"
              style={{ paddingLeft: "5px", marginLeft: "10px" }}
            />{" "}
          </a>
          <img src={window.origin + "/Images/Twitter.png"} alt="fb" width="35px" height="35px" style={{ paddingLeft: '5px', marginLeft: '10px' }} />
        </div>
      </div>
      <div className="row">
        <div
          className="col-12"
          style={{ borderTop: "1px solid white", marginTop: "20px" }}
        ></div>
        <div className="col-10 d-flex mt-5 pb-5">
          <div style={{ flexBasis: "10%" }}></div>

          <div style={{ flexBasis: "70%" }}>
            <div style={{ flexBasis: "50%" }}>
              <div style={{ display: "flex" }}>
                {/* <div
                style={{
                  marginRight: "30px",
                  borderBottom: "1px dashed white",
                }}
              >
                Terms and conditions
              </div> */}
                <div className="mr-5">
                  <Link
                    style={{ borderBottom: "1px dashed white", color: "white", textDecoration: "none" }}
                    className="headerHover"
                    // onMouseOver={hoverChange}
                    to="/TermsAndConditions"
                  >
                    Terms and Conditions
                </Link>
                </div>
                <div className="mr-5">
                  <Link
                    style={{ borderBottom: "1px dashed white", color: "white", textDecoration: "none" }}
                    className="headerHover"
                    // onMouseOver={hoverChange}
                    to="/PrivacyPolicy"
                  >
                    Privacy Policy
                </Link>

                </div>
                <div>
                  <Link
                    style={{ borderBottom: "1px dashed white", color: "white", textDecoration: "none" }}
                    className="headerHover"
                    // onMouseOver={hoverChange}
                    to="/RefundPolicy"
                  >
                    Refund Policy
                </Link>
                </div>
                {/* <div style={{ borderBottom: "1px dashed white" }}>
                Privacy Policy
              </div> */}
              </div>
            </div>
            <div style={{ flexBasis: "10%" }}></div>
            <div style={{ flexBasis: "20%" }}>
              {/* <p>© 2020 PathSoft. All rights reserved.</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
