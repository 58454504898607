import './App.css';
import LandingPage from './components/Landing_Page/LandingPage.jsx'
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <div >
         <LandingPage />
         
    </div>
  );
}
    export default App ;
