import React from "react";
import "./News.css";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

export default function News() {
  var items = [
    {
      date: "06/04/2021",
      img: "/Images/HadithImage.png",
      // header:'Benefits Of Async Await',
      // content:'Asynchronous functions are a good and bad thing in JavaScript'
    },
    {
      date: "01/04/20201",
      img: "/Images/CalApril.jpg",
      // header:'Benefits Of Async Await',
      // content:'Asynchronous functions are a good and bad thing in JavaScript'
    },
    {
      date: "06/04/2021",
      img: "/Images/MuftiAsjadRaza2.png",
      header: "Grand Mufti of India",
      content:
        "The Grand Mufti of India Hazrat Allama Mufti Muhammad Asjad Raza Khan Qadri Hafidahullahu Ta'ala / یلٰاہللاعت ہظفح lives in Bareilly Sharif. Please come after Asr Prayer to become mureed and to ask/seek for Dua",
    },
  ];
  return (
    <div className="section section-bg">
      <div className="chooseUsHeader">
        <p>MORE INFO ABOUT</p>
        <p>Latest News</p>
        <Grid container spacing={0}>
          <Grid item sm={1} md={1} lg={1}></Grid>
          <Grid item sm={10} md={10} lg={10}>
            <Grid container spacing={0}>
              {items.map((item, i) => (
                <NewsList key={i} item={item} />
              ))}
            </Grid>
          </Grid>

          <Grid item sm={1} md={1} lg={1}></Grid>
        </Grid>
      </div>
      <div style={{ display: "flex", marginTop: "0px", marginBottom: "0px" }}>
        <div style={{ flexBasis: "30%" }}></div>
        <div
          className="d-flex justify-content-center"
          style={{ flexBasis: "40%" }}
        >
          <Link style={{ decoration: "none", color: "white" }} to="/news">
            <button className="serviceCompoBtn">See All News</button>
          </Link>
        </div>
        <div style={{ flexBasis: "30%" }}></div>
      </div>
    </div>
  );
}

function DateButton(props) {
    if(props.item.date) {
        return <button className="newDateBtn">{props.item.date}</button>
    }
    return "";
}

export function NewsList(props) {
  return (
    <Grid
      className="d-flex justify-content-center"
      item
      xs={12}
      sm={6}
      md={4}
      lg={4}
    >
      <div className="newsCard">
        <img alt="pro" className="newsImage" src={props.item.img} />
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "-30px" }}
        >
        </div>
        <DateButton item={props.item} />
        <div>
          <h4
            className="mb-3"
            style={{ fontSize: "20px", fontWeight: "bolder !important" }}
          >
            {props.item.header}
          </h4>
          <p className="news">{props.item.content}</p>
        </div>
      </div>
    </Grid>
  );
}
