import React from 'react'
//import {  Redirect} from "react-router";
//import Header from '../Header/Header'
import Carousels from './Carousel/Carousel'
import Service from './Services/Services'
import WHY_CHOOSE_US from '../Why_Choose_Us/Why_Choose_Us'
import './Home_page.css'
import Experience from './Experience/Experience'
import OurWork from './Our_Works/OurWorks'
import Team from './Team/Team'
import Review from './Review/Review'
import News from './News/News'
import Carousel2 from './Carousel/Carousel2'
//import Footer from  '../Footer/Footer'

export default function Home_Page() {
    return (
        <div>
            <div>
                
                </div> 
                <Carousels />
                {/* <Carousel2/>            */}
                <Service />
                <WHY_CHOOSE_US /> 
                <Experience />
                <OurWork />
                <Team />
                <Review />
                <News />
                
            </div>
    )
}
