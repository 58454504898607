import React from 'react'
//import Header from '../Header/Header'
import Service from './Services/Services'
//import Why_Choose_Us from '../Why_Choose_Us/Why_Choose_Us'

export default function Home_Page() {
    return (
        <div>
            <Service />
        </div>
    )
}


