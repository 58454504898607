import React from "react";
import "../Home_Page/News/News.css";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { NewsList } from "../Home_Page/News/News";

export default function Projects() {
  var items = [
    {
      img: "/Images/NooriStaff-I.JPG",
      header: "Noori Staff Colony",
      content:
        "This Building is based on five Storey and the construction work is inforce.",
    },
    {
      img: "/Images/HM1.JPEG",
      header: "Hamidi Masjid",
      content: `This is a huge and unique Masjid having the capacity of more
      than 1200 persons at a time.This Masjid's finishing works is
      inforce.`,
    },
    {
      img: "/Images/QadriHostel.jpg",
      header: "Qadri Hostel",
      content: ` Our Proposed but expected to soon will start project Qadri
        Hostel will be based on the five storey with beautiful
        elevation and interior.This Hostel will welcome foreigner
        students as well as Indian students with well furnished
        apartments/rooms.`,
    },
  ];
  return (
    <div className="section section-bg">
      <div className="chooseUsHeader mb-5">
        <p>OUR WORKS</p>
        <p>Latest Projects</p>
        <Grid container spacing={0}>
          <Grid item sm={1} md={1} lg={1}></Grid>
          <Grid item sm={10} md={10} lg={10} xs={11}>
            <Grid container spacing={0}>
              {items.map((item, i) => (
                <NewsList key={i} item={item} />
              ))}
            </Grid>
          </Grid>

          <Grid item sm={1} md={1} lg={1}></Grid>
        </Grid>
      </div>
    </div>
  );
}
