import React from 'react'
import '../Home_Page/Services/Services'
//import ChevronRightIcon from '@material-ui/icons/ChevronRight';
//import Settings from '@material-ui/icons/Settings';
//import { Grid } from '@material-ui/core';

export default function Services() {
    return (
        <div className="serviceComponent">
               <p>AREAS WHAT WE SERV</p>
               <p>Our Refund Policy</p>
          <p>Coming soon...</p>
        </div>
    )
}