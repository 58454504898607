import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Tab, Tabs, Typography } from "@material-ui/core";
import FlipCard from "flip-card-react";
import "./Our_Works.css";
import { Link } from "react-router-dom";

/*
const cardStyle = {
    padding: 25,
    border: "solid 1px",
    borderRadius: 5,
    color: "#fff",
    width: 150
};
*/

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
}));

export default function OurWorks() {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="section section-bg">
      <div className="chooseUsHeader">
        <p>OUR WORKS</p>
        <p>Latest Projects</p>
      </div>
      <Grid container className={classes.root} spacing={0}>
        <Grid
          item
          sm={12}
          style={{ width: "100vw" }}
          className="section-bg d-flex flex-sm-row flex-column align-items-center"
        >
          <div className="ourWorkTabHead">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              className="ourWorksTabHead tabs"
              style={{ paddingLeft: "40px" }}
            >
              <Tab className="ourWorksTab" label="ALL" {...a11yProps(0)} />
              <Tab
                className="ourWorksTab"
                label="RUNNING PROJECTS"
                {...a11yProps(1)}
                style={{ marginRight: "20px" }}
              />
              <Tab
                className="ourWorksTab"
                label="FUTURE PROJECTS"
                {...a11yProps(2)}
                style={{ marginRight: "20px" }}
              />
              <Tab
                className="ourWorksTab"
                label="COMPLETED PROJECTS"
                {...a11yProps(3)}
                style={{ marginRight: "20px" }}
              />
            </Tabs>

            <TabPanel value={value} index={0}>
              <div className="cardPostion mt-5">
                <ALL />
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="cardPostion mt-5">
                <RUNNING_PROJECTS />
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div className="cardPostion mt-5">
                <FUTURE_PROJECTS />
              </div>
            </TabPanel>
            {/* <TabPanel value={value} index={3}>
              <div className="cardPostion">
                <COMPLETED_PROJECTS />
              </div>

            </TabPanel> */}
          </div>
        </Grid>
        <Grid item sm={1} className="section-bg"></Grid>
      </Grid>

      <div style={{ display: "flex", marginTop: "40px", marginBottom: "40px" }}>
        <div style={{ flexBasis: "40%" }}></div>
        <div
          className="d-flex justify-content-center"
          style={{ flexBasis: "20%" }}
        >
          <Link style={{ decoration: "none", color: "white" }} to="/projects">
            <button className="serviceCompoBtn"> See All Projects</button>
          </Link>
        </div>
        <div style={{ flexBasis: "40%" }}></div>
      </div>
    </div>
  );
}

function ALL() {
  const [isFlipped, setFlipped] = useState(false);
  const [isFlipped1, setFlipped1] = useState(false);
  const [isFlipped2, setFlipped2] = useState(false);
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.root}
      spacing={0}
    //className="mobileSpace"
    >
      {/* 1st card */}
      <Grid item sm={3} xs={12} className="chooseGridpadworks ">
        <FlipCard
          isFlipped={isFlipped}
          front={
            <div
              onMouseEnter={() => setFlipped((y) => !y)}
              className="flipcardFront"
            >
              <img
                src={window.location.origin + "/Images/NooriStaff-I.JPG"}
                alt="project"
              />
            </div>
          }
          back={
            <div
              onMouseLeave={() => setFlipped((y) => !y)}
              className="backcardFront"
            >
              <p className="backcardHead">Noori Staff Colony</p>

              <div className="backcardTextContainer">
                <p className="backcardText">
                  This Colony includes a 5-Storey Residential Building for the comfort of the
                  Staff (Faculty) of Jamiatur Raza . It's construction work is under progress.
                </p>

                <div className="ourWorksArrowHead">
                  <div className="ourWorksArrow">
                    <Link
                      style={{ decoration: "none", color: "white" }}
                      to="/comingSoon"
                    >
                      <img
                        className="arrowImg"
                        alt="arrow"
                        src={window.origin + "/Images/rightArrow.png"}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </Grid>
      {/* 1st card */}

      {/* 2nd card */}
      <Grid item sm={3} xs={12}  className="chooseGridpadworks">
        <FlipCard
          isFlipped={isFlipped1}
          front={
            <div
              onMouseEnter={() => setFlipped1((y) => !y)}
              className="flipcardFront"
            >
              <img
                src={window.location.origin + "/Images/HM1.JPEG"}
                alt="project"
              />
            </div>
          }
          back={
            <div
              onMouseLeave={() => setFlipped1((y) => !y)}
              className="backcardFront"
            >
              <p className="backcardHead">Hamidi Masjid</p>
              <div className="backcardTextContainer">
                <p className="backcardText">
                  This is a huge and unique Masjid in the Premises of
                  Jamiatur Raza having a capacity of more than 1200
                  persons at a time. The Finishing work of the Masjid is
                  inforce.
                </p>

                <div className="ourWorksArrowHead">
                  <div className="ourWorksArrow">
                    <Link
                      style={{ decoration: "none", color: "white" }}
                      to="/comingSoon"
                    >
                      <img
                        className="arrowImg"
                        alt="arrow"
                        src={window.origin + "/Images/rightArrow.png"}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </Grid>
      {/* 2nd card */}

      {/* 3rd card */}
      <Grid item sm={3} xs={12} className="chooseGridpadworks">
        <FlipCard
          isFlipped={isFlipped2}
          front={
            <div
              onMouseEnter={() => setFlipped2((y) => !y)}
              className="flipcardFront"
            >
              <img
                src={window.location.origin + "/Images/QadriHostel.jpg"}
                alt="project"
              />
            </div>
          }
          back={
            <div
              onMouseLeave={() => setFlipped2((y) => !y)}
              className="backcardFront"
            >
              <p className="backcardHead">Qadri Hostel</p>
              <div className="backcardTextContainer">
                <p className="backcardText">
                  Our proposed project (expected to be started soon) will be a 5-Storey Hostel
                  with a Beautiful Elevation and Super Class Interior Work. This hostel will
                  welcome the Students from abroad as well apart from the Indian Subcontinent
                  with well furnished rooms facilities.

                </p>

                <div className="ourWorksArrowHead">
                  <div className="ourWorksArrow">
                    <Link
                      style={{ decoration: "none", color: "white" }}
                      to="/comingSoon"
                    >
                      <img
                        className="arrowImg"
                        alt="arrow"
                        src={window.origin + "/Images/rightArrow.png"}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </Grid>
      {/* 3rd card */}
    </Grid>
  );
}

function RUNNING_PROJECTS() {
  const [isFlipped, setFlipped] = useState(false);
  const [isFlipped1, setFlipped1] = useState(false);
  const [isFlipped2, setFlipped2] = useState(false);
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.root}
      spacing={0}
    //className="mobileSpace"
    >
      {/* 1st card */}
      <Grid item sm={3} xs={12} className="chooseGridpadworks">
        <FlipCard
          isFlipped={isFlipped}
          front={
            <div
              onMouseEnter={() => setFlipped((y) => !y)}
              className="flipcardFront"
            >
              <img
                src={window.location.origin + "/Images/NooriStaff-I.JPG"}
                alt="project"
              />
            </div>
          }
          back={
            <div
              onMouseLeave={() => setFlipped((y) => !y)}
              className="backcardFront"
            >
              <p className="backcardHead">Noori Staff Colony</p>

              <div className="backcardTextContainer">
                <p className="backcardText">
                  This Colony includes a 5-Storey Residential Building for the comfort of the
                  Staff (Faculty) of Jamiatur Raza . It's construction work is under progress.
                </p>

                <div className="ourWorksArrowHead">
                  <div className="ourWorksArrow">
                    <Link
                      style={{ decoration: "none", color: "white" }}
                      to="/comingSoon"
                    >
                      <img
                        className="arrowImg"
                        alt="arrow"
                        src={window.origin + "/Images/rightArrow.png"}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </Grid>
      {/* 1st card */}

      {/* 2nd card */}
      <Grid item sm={3} xs={12} className="chooseGridpadworks">
        <FlipCard
          isFlipped={isFlipped1}
          front={
            <div
              onMouseEnter={() => setFlipped1((y) => !y)}
              className="flipcardFront"
            >
              <img
                src={window.location.origin + "/Images/HM1.JPEG"}
                alt="project"
              />
            </div>
          }
          back={
            <div
              onMouseLeave={() => setFlipped1((y) => !y)}
              className="backcardFront"
            >
              <p className="backcardHead">Hamidi Masjid </p>
              <div className="backcardTextContainer">
                <p className="backcardText">
                  This is a huge and unique Masjid in the Premises of
                  Jamiatur Raza having a capacity of more than 1200
                  persons at a time. The Finishing work of the Masjid is
                  inforce.
                </p>

                <div className="ourWorksArrowHead">
                  <div className="ourWorksArrow">
                    <Link
                      style={{ decoration: "none", color: "white" }}
                      to="/comingSoon"
                    >
                      <img
                        className="arrowImg"
                        alt="arrow"
                        src={window.origin + "/Images/rightArrow.png"}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </Grid>
      {/* 2nd card */}

      {/* 3rd card */}
      {/* <Grid item sm={3} xs={12} className="chooseGridpadworks">
        <FlipCard
          isFlipped={isFlipped2}
          front={
            <div
              onMouseEnter={() => setFlipped2((y) => !y)}
              className="flipcardFront"
            >
              <img
                src={window.location.origin + "/Images/project-1.jpg"}
                alt="project"
              />
            </div>
          }
          back={
            <div
              onMouseLeave={() => setFlipped2((y) => !y)}
              className="backcardFront"
            >
              <p className="backcardHead">MPlanner</p>
              <div className="backcardTextContainer">
                <p className="backcardText">
                  We work hard on every app to deliver top-notch features with
                  great UI that you won’t find anywhere else.
                </p>

                <div className="ourWorksArrowHead">
                  <div className="ourWorksArrow">
                  <Link
                      style={{ decoration: "none", color: "white" }}
                      to="/comingSoon"
                    >
                      <img
                        className="arrowImg"
                        alt="arrow"
                        src={window.origin + "/Images/rightArrow.png"}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </Grid> */}
      {/* 3rd card */}
    </Grid>
  );
}

function FUTURE_PROJECTS() {
  const [isFlipped, setFlipped] = useState(false);
  const [isFlipped1, setFlipped1] = useState(false);
  const [isFlipped2, setFlipped2] = useState(false);
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.root}
      spacing={0}
    //className="mobileSpace"
    >
      {/* 1st card */}
      <Grid item sm={3} xs={12} className="chooseGridpadworks">
        <FlipCard
          isFlipped={isFlipped}
          front={
            <div
              onMouseEnter={() => setFlipped((y) => !y)}
              className="flipcardFront"
            >
              <img
                src={window.location.origin + "/Images/QadriHostel.jpg"}
                alt="project"
              />
            </div>
          }
          back={
            <div
              onMouseLeave={() => setFlipped((y) => !y)}
              className="backcardFront"
            >
              <p className="backcardHead">Qadri Hostel</p>

              <div className="backcardTextContainer">
                <p className="backcardText">
                  Our proposed project (expected to be started soon) will be a 5-Storey Hostel
                  with a Beautiful Elevation and Super Class Interior Work. This hostel will
                  welcome the Students from abroad as well apart from the Indian Subcontinent
                  with well furnished rooms facilities.
                </p>

                <div className="ourWorksArrowHead">
                  <div className="ourWorksArrow">
                    <Link
                      style={{ decoration: "none", color: "white" }}
                      to="/comingSoon"
                    >
                      <img
                        className="arrowImg"
                        alt="arrow"
                        src={window.origin + "/Images/rightArrow.png"}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </Grid>
      {/* 1st card */}

      {/* 2nd card */}
      {/* <Grid item sm={3} xs={12} className="chooseGridpadworks">
        <FlipCard
          isFlipped={isFlipped1}
          front={
            <div
              onMouseEnter={() => setFlipped1((y) => !y)}
              className="flipcardFront"
            >
              <img
                src={window.location.origin + "/Images/project-1.jpg"}
                alt="project"
              />
            </div>
          }
          back={
            <div
              onMouseLeave={() => setFlipped1((y) => !y)}
              className="backcardFront"
            >
              <p className="backcardHead">MPlanner</p>
              <div className="backcardTextContainer">
                <p className="backcardText">
                  We work hard on every app to deliver top-notch features with
                  great UI that you won’t find anywhere else.
                </p>

                <div className="ourWorksArrowHead">
                  <div className="ourWorksArrow">
                  <Link
                      style={{ decoration: "none", color: "white" }}
                      to="/comingSoon"
                    >
                      <img
                        className="arrowImg"
                        alt="arrow"
                        src={window.origin + "/Images/rightArrow.png"}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </Grid> */}
      {/* 2nd card */}

      {/* 3rd card */}
      {/* <Grid item sm={3} xs={12} className="chooseGridpadworks">
        <FlipCard
          isFlipped={isFlipped2}
          front={
            <div
              onMouseEnter={() => setFlipped2((y) => !y)}
              className="flipcardFront"
            >
              <img
                src={window.location.origin + "/Images/project-1.jpg"}
                alt="project"
              />
            </div>
          }
          back={
            <div
              onMouseLeave={() => setFlipped2((y) => !y)}
              className="backcardFront"
            >
              <p className="backcardHead">MPlanner</p>
              <div className="backcardTextContainer">
                <p className="backcardText">
                  We work hard on every app to deliver top-notch features with
                  great UI that you won’t find anywhere else.
                </p>

                <div className="ourWorksArrowHead">
                  <div className="ourWorksArrow">
                  <Link
                      style={{ decoration: "none", color: "white" }}
                      to="/comingSoon"
                    >
                      <img
                        className="arrowImg"
                        alt="arrow"
                        src={window.origin + "/Images/rightArrow.png"}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </Grid> */}
      {/* 3rd card */}
    </Grid>
  );
}

function COMPLETED_PROJECTS() {
  const [isFlipped, setFlipped] = useState(false);
  const [isFlipped1, setFlipped1] = useState(false);
  const [isFlipped2, setFlipped2] = useState(false);
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.root}
      spacing={0}
    //className="mobileSpace"
    >
      {/* 1st card */}
      <Grid item sm={3} xs={12} className="chooseGridpadworks">
        <FlipCard
          isFlipped={isFlipped}
          front={
            <div
              onMouseEnter={() => setFlipped((y) => !y)}
              className="flipcardFront"
            >
              <img
                src={window.location.origin + "/Images/NooriStaff-I.JPG"}
                alt="project"
              />
            </div>
          }
          back={
            <div
              onMouseLeave={() => setFlipped((y) => !y)}
              className="backcardFront"
            >
              <p className="backcardHead">Noori Staff Colony</p>

              <div className="backcardTextContainer">
                <p className="backcardText">
                  This Building is based on five Storey and the construction
                  work is inforce.
                </p>

                <div className="ourWorksArrowHead">
                  <div className="ourWorksArrow">
                    <Link
                      style={{ decoration: "none", color: "white" }}
                      to="/comingSoon"
                    >
                      <img
                        className="arrowImg"
                        alt="arrow"
                        src={window.origin + "/Images/rightArrow.png"}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </Grid>
      {/* 1st card */}

      <Grid sm={1}></Grid>

      {/* 2nd card */}
      <Grid item sm={3} xs={12} className="chooseGridpadworks">
        <FlipCard
          isFlipped={isFlipped1}
          front={
            <div
              onMouseEnter={() => setFlipped1((y) => !y)}
              className="flipcardFront"
            >
              <img
                src={window.location.origin + "/Images/HM1.JPEG"}
                alt="project"
              />
            </div>
          }
          back={
            <div
              onMouseLeave={() => setFlipped1((y) => !y)}
              className="backcardFront"
            >
              <p className="backcardHead">Hamidi Masjid </p>
              <div className="backcardTextContainer">
                <p className="backcardText">
                  This is a huge and unique Masjid having the capacity of more
                  than 1200 persons at a time.This Masjid's finishing works is
                  inforce.
                </p>

                <div className="ourWorksArrowHead">
                  <div className="ourWorksArrow">
                    <Link
                      style={{ decoration: "none", color: "white" }}
                      to="/comingSoon"
                    >
                      <img
                        className="arrowImg"
                        alt="arrow"
                        src={window.origin + "/Images/rightArrow.png"}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </Grid>
      {/* 2nd card */}

      <Grid sm={1} xs={12}></Grid>

      {/* 3rd card */}
      <Grid item sm={3} xs={12} className="chooseGridpadworks">
        <FlipCard
          isFlipped={isFlipped2}
          front={
            <div
              onMouseEnter={() => setFlipped2((y) => !y)}
              className="flipcardFront"
            >
              <img
                src={window.location.origin + "/Images/QadriHostel.jpg"}
                alt="project"
              />
            </div>
          }
          back={
            <div
              onMouseLeave={() => setFlipped2((y) => !y)}
              className="backcardFront"
            >
              <p className="backcardHead">Qadri Hostel </p>
              <div className="backcardTextContainer">
                <p className="backcardText">
                  Our Proposed but expected to soon will start project Qadri
                  Hostel will be based on the five storey with beautiful
                  elevation and interior.This Hostel will welcome foreigner
                  students as well as Indian students with well furnished
                  apartments/rooms.
                </p>

                <div className="ourWorksArrowHead">
                  <div className="ourWorksArrow">
                    <Link
                      style={{ decoration: "none", color: "white" }}
                      to="/comingSoon"
                    >
                      <img
                        className="arrowImg"
                        alt="arrow"
                        src={window.origin + "/Images/rightArrow.png"}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </Grid>
      {/* 3rd card */}

      <Grid sm={1}></Grid>
    </Grid>
  );
}
