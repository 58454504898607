import React from 'react'
import Breadcrumb2 from '../BreadCrumb/BreadCrumb'
import '../AboutUs_Page/Services/Service.css'
//import ChevronRightIcon from '@material-ui/icons/ChevronRight';
//import Settings from '@material-ui/icons/Settings';
//import { Grid } from '@material-ui/core';

export default function Services() {
    return (
        <>
            <Breadcrumb2 pagename="Monthly Sunni Duniya" />
        {/* <div className="departmentComponent">
               <p>AREAS WHAT WE SERVE</p>
               <p> Monthly Sunni Duniya</p>
               <p><img src="/Images/uc3.png" alt=""/></p>
        </div> */}
        <div className="main-content pt-5">
            <div className="row">
                <div className="col-12" style={{ padding: '0px 5vw' }}>
                <div className="wrapp-page-title page-title-center">
							{/* <div className="page-subtitle">MORE INFO</div> */}
							<h1 className="page-title">Monthly Sunni Duniya</h1>
						</div>
                        <div className="content">
                            <div >
                                <img className="img-style6" src="/Images/NewSDLogo.png" alt="" />

                            </div>
                            <p>It is vital for the growth and expansion of any organization or movement that it has its own managed and organized media. Without which, the duties of propagation cannot be performed effectively. Otherwise, the message of any movement or an organization would not be long lasting and consistent. The Great theologian and Leader, AlaHazrat Ash-Shah Imam Ahmad Raza Khan (Alayhir Rahmah) also focused on its importance in his renowned 10-points agenda, He says:
“Daily or weekly newspapers and magazines having articles in the favour of the religion should be published and distributed around the country.” <br />
	In the present day and age, while the opponents are trying to defame Islam themselves and on the other hand, they are funding the sell-outs who are trying their best to portray the wrong image of Islam. It becomes increasingly necessary to publicize the true teachings of Islam in addition to the pursuit of the opponents, it equally important to track down and expose their slaves as well. Realizing this obligation, His Excellency, the great Gnostic, Jurist of Islam, Islamic Chief Justice of India, Taaj al-Shari’ah Allamah Mufti Muhammad Akhtar Raza Khan Qadiri Baraylawi (Alayhir Rahmah) initiated “Monthly Sunni Duniya” Magazine in the year 1982, which is very vigilantly carrying out its duties and truly representing the Ahl as-Sunnah wa al-Jama’ah. Very few magazines have continued their journey in the field of honest journalism for such a long period. <br />
Under the active supervision of Mufti Muhammad Asjad Raza Khan Qadiri Baraylawi (May Allah preserve him), the monthly Sunni Duniya magazine is gaining momentum by the grace of Allah Almighty fulfilling the modern day requirements of journalism, printing and publishing.
So please take part in the progress of this Islamic monthly magazine.
</p>
                        </div>
                </div>
            </div>
        </div>
        </>
    )
}