import React from "react";
import "../Home_Page/News/News.css";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { NewsList } from '../Home_Page/News/News';

export default function News() {
  var items = [
    {
      date: "06/04/2021",
      img: "/Images/HadithImage.png",
      // header:'Benefits Of Async Await',
      // content:'Asynchronous functions are a good and bad thing in JavaScript'
    },
    {
      date: "01/04/20201",
      img: "/Images/CalApril.jpg",
      // header:'Benefits Of Async Await',
      // content:'Asynchronous functions are a good and bad thing in JavaScript'
    },
    {
      date: "06/04/2021",
      img: "/Images/MuftiAsjadRaza2.png",
      header: "Grand Mufti of India",
      content:
        "Grand Mufti of India Hazrat Allama Muhammad Asjad Raza Khan Qadri Hafidahillahi Ta'ala is in Bareilly Shareef. To become mureed and for Dua please come after Asr Prayer.",
    },
  ];
  return (
    <div className="section section-bg">
      <div className="chooseUsHeader mb-5">
        <p>MORE INFO ABOUT</p>
        <p>Latest News</p>
        <Grid container spacing={0}>
          <Grid item sm={1} md={1} lg={1}></Grid>
          <Grid item sm={10} md={10} lg={10}>
            <Grid container spacing={0}>
              {items.map((item, i) => (
                <NewsList key={i} item={item} />
              ))}
            </Grid>
          </Grid>

          <Grid item sm={1} md={1} lg={1}></Grid>
        </Grid>
      </div>
    </div>
  );
}
