import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import './UIElements.css'
//import { Grid } from '@material-ui/core';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 504,
    marginLeft: '10%'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

export default function VerticalTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>

      <div className="uiHeader">
        UI Elements
</div>
      <div className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          <Tab label="Typography" {...a11yProps(0)} />
          <Tab label="Buttons" {...a11yProps(1)} />
          <Tab label="Forms" {...a11yProps(2)} />

        </Tabs>
        <TabPanel value={value} index={0}>
          <TypographyTab />
        </TabPanel>
        <TabPanel value={value} index={1}>
          Item Two
      </TabPanel>
        <TabPanel value={value} index={2}>
          Item Three
      </TabPanel>
        <TabPanel value={value} index={3}>
          Item Four
      </TabPanel>
        <TabPanel value={value} index={4}>
          Item Five
      </TabPanel>
        <TabPanel value={value} index={5}>
          Item Six
      </TabPanel>
        <TabPanel value={value} index={6}>
          Item Seven
      </TabPanel>

      </div>

    </div>
  );
}


function TypographyTab() {
  return (
    <div className="ui-item content active">
      <h1>H1 Heading</h1>
      <h2>H2 Heading</h2>
      <h3>H3 Heading</h3>
      <h4>H4 Heading</h4>
      <h5>H5 Heading</h5>
      <h6>H6 Heading</h6>

      <p className="para">Welcome to our wonderful world. We sincerely hope that each and every user entering our website will find
      exactly what he/she is looking for. With advanced features of activating account and new login widgets,
					you will definitely have a great experience of using our web page.</p>

      <h3>HTML Text Elements</h3>

      <p>You can use the mark tag to <mark>highlight</mark> text.</p>
      <p><del>This line of text is meant to be treated as deleted text.</del></p>
      <p><s>This line of text is meant to be treated as no longer accurate.</s></p>
      <p><ins>This line of text is meant to be treated as an addition to the document.</ins></p>
      <p><u>This line of text will render as underlined</u></p>
      <p><small>This line of text is meant to be treated as fine print.</small></p>
      <p><strong>This line rendered as bold text.</strong></p>
      <p><em>This line rendered as italicized text.</em></p>
      <p>This line width <a href="#!">Link</a></p>
      <br></br>

    </div>
  )
}