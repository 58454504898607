import React from 'react';
import './Why_Choose_Us.css';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';



const useStyles = makeStyles((theme) => ({
	root: {
	  flexGrow: 1,
	  paddingLeft: '6rem',
	  paddingRight: '6rem'
	}
  }));

export default function Why_Choose_Us() {
	
	const classes = useStyles();
	const chooseUS=[{count:'01',title:'Non-Political Religious Organization ',description:'Imam Ahmad Raza Trust is a non-political and non-profit organisation.'}
,{count:'02',title:'Quality Education ',description:"We provide quality and authentic education to the learners to understand real Islamic beliefs and Islamic characteristics."}
,{count:'03',title:'Working for Social Betterment',description:'We work for the social betterment of the society, especially the Muslims and downtrodden.'}
,{count:'04',title:'Modern Technologies ',description:'We impart traditional Islamic knowledge and beliefs using Modern Technologies.'}
,{count:'05',title:'Our Free Services',description:'We provide several free services to the Islamic World like Islamic Apps, Seminars, Religious Verdicts and many more.'}
,{count:'06',title:'We Accept ',description:"We accept Zakaat, Sadaqaat and Atiyaat from anyone who bears real Islamic belief and we utilise these according to their wishes. [Heela-e-Shar'ee]"}

]

    return (
        <section className="section section-bg">
			<Grid container className={classes.root} spacing={0}>			
					
						<div className="col-12">
							<div className="chooseUsHeader">
								<p>Why Choose Us</p>
								<p>SOME REASONS</p>
							</div> 
						</div>						
						{chooseUS.map((item,i) =>
						<Grid item sm={6} xs={12} lg={4} md={6} className="chooseGridpad" key={i}>
                        <div className="item">							
							<div className="advantages-item">
						       <div className="advantages-item-count">{item.count}</div>
								<div className="advantages-item-info">
						         <h4 className="advantages-item-title">{item.title}</h4>
									<div className="advantages-item-desc">
						             <p>{item.description}</p>
									</div>
								</div>
							</div>
						</div>
                       </Grid>
						)}						
			</Grid>
		</section>
    )
}
