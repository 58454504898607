import React from "react";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import '../Home_Page/Services/Service.css'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Breadcrumb2 from "../BreadCrumb/BreadCrumb";

export default function News() {
  var items = [
    {
      img: "/Images/gallery (1).png",
    },
    {
      img: "/Images/gallery (12).png",
    },
    {
      img: "/Images/002.png",
    },
    {
      img: "/Images/gallery (9).png",
    },
    {
      img: "/Images/gallery (2).png",
    },
    {
      img: "/Images/gallery (3).png",
    },
    {
      img: "/Images/ClassRoomHifz.png",
    },
    {
      img: "/Images/gallery (7).png",
    },
    {
      img: "/Images/gallery (8).png",
    },
    
  ];
  return (
    <div className="main-content">
     <nav className="bread-crumbs">
				<div>
					<div className="row">
						<div className="col-12">
							<ul className="bread-crumbs-list ">
								<li>
									<a href="index.html" className="hoverNavHead">Home</a>
									{/* &nbsp;&gt;&nbsp; */}
									</li>
									<ChevronRightIcon className="material-icons md-18" />
								<li className=""><a href="Gallery" className="hoverNavHead">Gallery</a></li>
							</ul>
						</div>
					</div>
				</div>
			</nav>
{/* <nav className="bread-crumbs">
<div>
<div className="row">
<div className="col-12 ">
  <ul className="bread-crumbs-list">
    <li>
      <a href="index.html" className="hoverNavHead">Home</a>
      <span><ChevronRightIcon className="material-icons md-18 " />	</span>

    </li>
    <li><a href="Gallery" className="hoverNavHead">Gallery</a></li>
  </ul>
</div>
</div>
</div>
</nav> */}
    <div className="section section-bg">
      <div className="chooseUsHeader mb-5">
        <p>ABOUT US IN THE PHOTO</p>
        <p>Gallery</p>
        <Grid container spacing={0}>
          <Grid item xs={1} md={1} lg={1}></Grid>
          <Grid item xs={10} md={10} lg={10}>
            <Grid container spacing={0}>
              {items.map((item, i) => (
                <Grid item key={i} lg={4} md={4} sm={4} xs={12} className="pr-sm-5 pr-md-3 pt-5">
                     <img
                        style={{width: '100%', height: '100%', borderRadius: '12px'}}
                        src={window.location.origin + item.img}
                        alt={item.alt}
                        />
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item sm={1} md={1} lg={1}></Grid>
        </Grid>
      </div>
    </div>
              </div>
  );
}

