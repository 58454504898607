import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Link } from "react-router-dom";
import {
    //Button, 
    Paper
} from '@material-ui/core'
import './Carousel.css'
export default function Carousels(props) {
    var items = [
        {
            id:1,
            name: "Imam Ahmad Raza Trust",
            image: '/Images/intro-img1.jpg',
            description: "The Trust strongly believes that education is the bedrock of progress and development; hence, the Trust is actively participating in educating the individuals irrespective of their financial background.",
            button: 'Read More',
            // button2: 'Get in Touch'
        },
        {
            id:2,
            name: "Imam Ahmad Raza Trust",
            image: '/Images/intro-img2.jpg',
            description: "The Trust strongly believes that education is the bedrock of progress and development; hence, the Trust is actively participating in educating the individuals irrespective of their financial background.",
            button: 'Read More',
            // button2: 'Get in Touch'
        },
        {
            id:3,
            name: "Imam Ahmad Raza Trust",
            image: '/Images/intro-img3.jpg',
            description: "The Trust strongly believes that education is the bedrock of progress and development; hence, the Trust is actively participating in educating the individuals irrespective of their financial background.",
            button: 'Read More',
            // button2: 'Get in Touch'
        },
        {
            id:4,
            name: "Jamat Raza-e-Mustafa",
            image: '/Images/Jamatrazaemustafa.jpg',
            description: "Jamat Raza-e-Mustafa is a universal organisation founded by Imam Ahmad Raza Khan in 1920.The grand Imam established it to make Muslim religiously, socially and financially strong as well as powerful.",
            button: 'Read More',
            // button2: 'Get in Touch'
        }
        // {
        //     name: "Imam Ahmad Raza Trust",
        //     image: '/Images/JRMEduOnline.jpg',
        //     description: "The Trust strongly believes that education is the bedrock of progress and development; hence, The Trust is actively participating in educating individuals irrespective of their financial background.",
        //     button: 'Read More',
        //     // button2: 'Get in Touch'
        // },
    ]

    return (
        <Carousel
            interval={1000000}
            indicatorContainerProps={{ className: 'Carousel' }}
            animation="slide"
            indicatorProps={{ className: 'carouselindicator' }}
            activeIndicatorProps={{ className: 'activeCarouselindicator' }}
        >
            {
                items.map((item, i) => <Item key={i} item={item} />)
            }

        </Carousel>
    )
}

function Item(props) {
    return (


        <Paper className="container">
            <img src={window.location.origin + props.item.image} alt="bg" className="carouselBg" />

            <div className="centered">
                <h1>{props.item.name}</h1>
            <p>{props.item.description}</p>

                <Link to="/about">
                    <button className="carouselBtn">
                        {props.item.button}
                    </button>
                </Link>

                <button className="carouselBtn1">
                    {props.item.button2}
                </button>


            </div>
        </Paper>
    )
}