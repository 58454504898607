import React, { useState, useEffect } from 'react'
import axios from "axios";
import { Row } from 'react-foundation';
import { Button, Col } from 'react-bootstrap'
import { Card, CardDeck, CardGroup, ListGroup, ListGroupItem, Container, Grid } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";

function Books() {
    const [cardInfo, setData] = useState([]);
    console.log(cardInfo);

    useEffect(() => {
        axios
            .get(
                "https://imamahmadrazatrust-api.azurewebsites.net/api/book"
            )
            .then((response) => {
                setData(response.data);
                console.log(response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    const renderCard = (card, index) => {
        return (
            <Col md="4" lg="3" sm="2">
                <Card key={index} class="card" style={{ marginBottom: "1.5rem" }}>
                    <Card.Img variant="top" src={card.image}  />
                    <Card.Body>
                        <Card.Title class="card-title" style={{ fontSize: "2rem"}}>{card.name}</Card.Title>
                        <Card.Text class="card-text" style={{ fontSize: "1.5rem" }}>{card.desc}</Card.Text>
                    </Card.Body>
                    <ListGroup class="list-group" style={{ fontSize: "1.5rem" }}>
                        <ListGroupItem> {card.Name}</ListGroupItem>
                        <ListGroupItem><b>Author:</b> {card.author}</ListGroupItem>
                        <ListGroupItem><b>Language:</b>{card.language}</ListGroupItem>
                        <ListGroupItem><b>Category:</b>{card.category}</ListGroupItem>
                    </ListGroup>
                    <Card.Link class="card-link" style={{ fontSize: "1.5rem", paddingLeft: "1rem" }} href={card.url} target="_blank">Download</Card.Link>
                </Card>
            </Col>
        );
    };
    return (
        <>
            <Breadcrumb class="bread-crumbs">
                <Breadcrumb.Item href="Index">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>Books</Breadcrumb.Item>
            </Breadcrumb>
            <Container class="container" style={{ marginTop: "1.5rem" }}>
                <Row>
                    {cardInfo.map(renderCard)}
                </Row>
            </Container>
        </>
    );
};

export default Books