import React from 'react'
import Breadcrumb2 from '../BreadCrumb/BreadCrumb'
import '../AboutUs_Page/Services/Service.css'
//import ChevronRightIcon from '@material-ui/icons/ChevronRight';
//import Settings from '@material-ui/icons/Settings';
//import { Grid } from '@material-ui/core';

export default function Services() {
    return (
        <>
            {/* <div className="departmentComponent">
               <p>AREAS WHAT WE SERVE</p>
               <p> Jamat Raza e Mustafa</p>
               <p><img src="/Images/uc3.png" alt=""/></p>
            </div> */}
            <div className="main-content">
            <Breadcrumb2 pagename="Jamat Raza-e-Mustafa" />
                <div className="row">
                    <div className="col-12 "  style={{ padding: '0px 5vw' }}>
                        <div className="wrapp-page-title page-title-center">
							{/* <div className="page-subtitle">MORE INFO</div> */}
							<h1 className="page-title pt-5">Jamat Raza-e-Mustafa</h1>
						</div>
                        <div className="content">
                        <div >
								<img className="img-style5" src="/Images/NewJRMLogo.png" alt />
							</div>
                            <p className="">
                            The great services of Aala Hazrat Imam Ahmad Raza Khan Fazil-E-Barelvi are Global, Really a versatile genius, leading scholar, eminent jurist of his time and staunch upholder of Holy Prophet’s (Sallallahu Aliahe Wasallam) traditions & revivalist of Present century who devoted himself with all the energy at his command to the cause of Deen-E-Mateen, to safeguard, protect and promote the spirit of Shariah he cared least for scolding and sarcasms of their who did not agree with him in the interpretation of the ways of Allah
He did not run after the paraphernalia of worldly life, preferred to spend his capabilities to compose the poetry in praise of Holy Prophet (Allah's Grace and Peace be upon him) His reward in this world and the world hereafter cannot be imagined.
Hazrat Fazil-E-Barelvi has written over one thousand treatises. He exhaustively dealt with every topic he touched. Aala Hazrat kept the same pattern as adopted by the renowned writers of the Islam, but he excelled in the explanation and expansion of the most difficult and complicated Subject matters in relatively few and simple words.
When India had become a centre of anti Muslim movement and there were so many powers and movements who wanted to remove Islam and Muslims from the sub-continent. <br />
In order to give Religious, social and economic stability to the Muslim Ummah, Aala Hazrat Imam Ahmed Raza Qadri Muhad'dith Barelvi Alaihir Rahma established Jamat Raza-e-Mustafa on the 7th Rabi ul Aakhir 1339 Hijri co-inciding with 17th December 1920. One of the clear aims of this movement, is to also refute the deviant sects, and safeguard the beliefs (Aqaa'id) of the Muslims. <br />
Jamat Raza-E-Mustafa has done various glorious achievement during 1921 to 1926 AD. In 1960 Jamat Raza-E-Mustafa had came to an end. At last in October 1963 Huzoor Mufti-E-Azam Hind Alaihir Rahma has re-established this organization and followings rules and regulations were also made.

                            </p>
                            <p>
                                <ul>
                                    <li>Huzoor Mufti-E-Azam Hind will be lifelong president of Jamat Raza-e-Mustafa.</li>
                                    <li>Jamat Raza-E-Mustafa will establish and supervise all the branches of Jamat at Local, District and state level.</li>
                                    <li>All the Sunni institutions All over India and organizations of Country will be under the Jamat Raza-E-Mustafa.</li>
                                    <li>If any dispute will arise between any Sunni organization and the other Jamat Raza-e-Mustafa will work as a Judiciary and solve the dispute.</li>
                                </ul>
                            </p>
                            <h1> <strong>Aims and Objects of Jamat Raza-E-Mustafa</strong></h1>
                            <p>Imam-E-Ahle Sunnat was a scholarly writer having vast knowledge and vision. He had extensive knowledge and deep understanding of Hadith and Jurisprudence. His Judicious opinions to various Queries from different scholars show unique competence and comprehensive vision of thought, Quranic insight and ingenious. There are some aims and objects of Jamat Raza-E-Mustafa.
                                <ul>
                                    <li>    Progress of Ahl-E-Sunnat wal Jamat especially Unity of Ahl-E-Sunnnat, progress of religion and development of knowledge of Ahl-E-Sunnat.</li>
                                    <li>    To work in the field of Success of the people of Ahl-E-Sunnat and establish Islamic educational centre.</li>
                                    <li>	Law: (Present and future) For the benefit of Ahl-E- Sunnat make an  amendment of various law for this work do appeal and make sermon for the Government and members of Parliament and Assemblies.</li>
                                    <li>	To establish a huge Library, Having a huge quantity of Islamic books and contemporary books and journals and magazine etc.</li>
                                    <li>	To work of the progress of Ahl-E-Sunnat in the cities, towns and as well as in rural areas.</li>
                                    <li>	To make brotherhood and unity between Muslims in every area of the country.</li>
                                    <li>	To establish the branches of Jamat in Tahsil, towns, cities (in local level).</li>
                                    <li>	To establish Darul Qaza and Darul Ifta(Hanfi sect of Ahl-E-Sunnat).</li>
                                    <li>	Supervise the property of Muslim Waqf, Masjid etc.</li>
                                    <li>	To work for the contributions of such money for the work above said to make members in the whole country and give training for its member</li>
                                </ul>
                            </p>
                            </div>
                    </div>
                </div>

            </div>
        </>
    )
}