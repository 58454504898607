import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import '../Department/Department.css'
import { Link } from "react-router-dom"
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

function Breadcrumb2(props) {
    return (
        <div>
            <div className="main-contents">
            <nav className="bread-crumbs">
				<div>
					<div className="row">
						<div className="col-12">
							<ul className="bread-crumbs-list ">
								<li>
									<a href="index.html" className="hoverNavHead">Home</a>
									{/* &nbsp;&gt;&nbsp; */}
									</li>
									<ChevronRightIcon className="material-icons md-18" />
								<li className=""><a href="Department" className="hoverNavHead">Department</a></li>
                <ChevronRightIcon className="material-icons md-18" />
								<li className=""><a href="" className="hoverNavHead">{props.pagename}</a></li>
							</ul>
						</div>
					</div>
				</div>
			</nav>
        </div>
        </div>
    )
}

export default Breadcrumb2
