import React from 'react'
import Breadcrumb2 from '../BreadCrumb/BreadCrumb'
import './Department.css'
//import ChevronRightIcon from '@material-ui/icons/ChevronRight';
//import Settings from '@material-ui/icons/Settings';
//import { Grid } from '@material-ui/core';

export default function Services() {
    return (
        <>
            <Breadcrumb2 pagename="Centre For Judicial Verdict"/>
        <div className="departmentComponent">
               <p>AREAS WHAT WE SERVE</p>
               <p> Centre For Judicial Verdict</p>
               <p><img src="/Images/uc3.png" alt=""/></p>
        </div>
        </>
    )
}