import React from 'react'
import './gridblog.css'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
//import Settings from '@material-ui/icons/Settings';

export default function GridBlog() {
    var items = [
        {
            head:'Benefits of Async/Await in Programming',
            img:'/Images/news-img-2.jpg',
            content:'On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment...'
        },
        {
            head:'Key Considerations Of IPaaS',
            img:'/Images/news-img-1.jpg',
            content:'On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment...'
        },
        {
            head:'Hibernate Query Language',
            img:'/Images/news-img-3.jpg',
            content:'On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment...'
        }
    ]
    return (
        <div>
                   <nav className="bread-crumbs">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<ul className="bread-crumbs-list">
								<li>
									<a href="index.html" className="hoverNavHead">Home</a>
                                    <span><ChevronRightIcon className="material-icons md-18"/>	</span>
                                    								
								</li>
								<li><a href="GridBlog" className="hoverNavHead">Blog</a></li>
							</ul>
						</div>
					</div>
				</div>
			</nav>
    
    
        <div className="serviceComponent">

               <p>MOST INTERESTING ARTICLES</p>
               <p>Blog</p>
               <div className="serviceCompContainer">
                   <div style={{flexBasis:'20%'}}>

                   </div>
               {
                items.map( (item, i) => 
                <div className="news-item item-style">
								<a href="blog-post.html" className="news-item-img">
									<img className="lazy" src={item.img} alt="alt"/>
								</a>
								<div className="news-item-info">
									<div className="news-item-date">07/01/2020</div>
									<h4 className="news-item-title item-title">
										<a href="blog-post.html" title={item.head}>{item.head}</a>
									</h4>
									<div className="news-item-desc">
										<p>{item.content}</p>
									</div>
								</div>
							</div>
                
                
                 )
            }
            <div style={{flexBasis:'20%'}}>

</div>
            </div>
            <div className="serviceCompContainer" style={{marginTop:'15px'}}>
                   <div style={{flexBasis:'20%'}}>

                   </div>
               {
                items.map( (item, i) =>  <div className="news-item item-style">
                <a href="blog-post.html" className="news-item-img">
                    <img className="lazy" src={item.img} alt="alt"/>
                </a>
                <div className="news-item-info">
                    <div className="news-item-date">07/01/2020</div>
                    <h4 className="news-item-title item-title">
                        <a href="blog-post.html" title={item.head}>{item.head}</a>
                    </h4>
                    <div className="news-item-desc">
                        <p>{item.content}</p>
                    </div>
                </div>
            </div>)
            }
            <div style={{flexBasis:'20%'}}>

</div>
            </div>
            <div className="serviceCompContainer" style={{marginTop:'15px'}}>
                   <div style={{flexBasis:'20%'}}>

                   </div>
               {
                items.map( (item, i) =>  <div className="news-item item-style">
                <a href="blog-post.html" className="news-item-img">
                    <img className="lazy" src={item.img} alt="alt"/>
                </a>
                <div className="news-item-info">
                    <div className="news-item-date">07/01/2020</div>
                    <h4 className="news-item-title item-title">
                        <a href="blog-post.html" title={item.head}>{item.head}</a>
                    </h4>
                    <div className="news-item-desc">
                        <p>{item.content}</p>
                    </div>
                </div>
            </div> )
            }
            <div style={{flexBasis:'20%'}}>

</div>
            </div>


            <div style={{display:'flex',marginTop:'40px',marginBottom:'40px'}}>
                <div style={{flexBasis:'50%'}}>

                </div>
                <div style={{flexBasis:'20%'}}>
                        
                </div>
                <div style={{flexBasis:'40%'}}>

                </div>
            </div>          
               
        </div>
        </div>
    )
}


/*function Item(props)
{
    return (
        
           
                   <div className="serviceCard">
                       <img src={window.location.origin+props.item.img} className="deptImg" alt="alt" />
                            
                       <div>
                           <h3>{props.item.head}</h3>
                           <p className="serviceCompoCardTxt">
                           {props.item.content}
                           </p>
                     
                        </div>
                   </div>
               
                   
    )}*/