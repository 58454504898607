import React from "react";
import "../Home_Page/Services/Services";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
//import Settings from '@material-ui/icons/Settings';
import { Link } from "react-router-dom";

import { Grid } from "@material-ui/core";
import { Breadcrumb } from "react-bootstrap";

export default function Services() {
  var items = [
    {
      head: "Center of Islamic Studies Jamiatur Raza",
      img: "/Images/NewJamiaLogo.png",
      route: "/CenterOfIslamicStudiesJamiaturRaza",
      content:
        "Impressed by continuous service to religion and community,the Indian subcontinent unified to proclaim loudly the slogan of “Markaz e Ahle Sunnat Bareilly Shareef”. Because in the veins of that Markaz, ‘Imam Ahmad Raza Khan’s’ knowledge and wisdom runs as blood",
    },
    {
      head: "Shar'ee Council of India",
      img: "/Images/NewSCILogo.png",
      route: "/ShareeCouncilOfIndia",
      content:
        "Shar'ee Council of India was established by Huzoor Tajush Shari'ah Allama Mufti Muhammad Akhtar Raza Khan Qadri Azhari Raziyallahu Ta'ala Anhu Rahimahullahu Ta’ala /رَ حَ مَ هَاَ للَتَ  عَالىٰforproviding the solutions of burning topics of the society, which violate the Islamic Shari'ah.",
    },
    {
      head: "Monthly Sunni Duniya",
      img: "/Images/NewSDLogo.png",
      route: "/MonthlySunniDuniya",
      content:
        "The Monthly Sunni Duniya is an example of religious Journalism.This Magazine represents the real voice of Markaze Ahle Sunnat.This wide spread magazine is continue since two decades.",
    },
    {
      head: "Markazi Darul Ifta ",
      img: "/Images/NewMDILogo.png",
      route: "/MarkaziDarulIfta",
      content:
        "The Markazi Darul Ifta is a pillar in Islamic Jurispudence since the era of Aala Hazrat Imam Ahmad Raza Khan and following thier way of deleivering verdict.This Darul Ifta sentencing their decisions on every topic which is related to Islamic beliefs and Sharia. ",
    },
    {
      head: "Jamat Raza-e Mustafa ",
      img: "/Images/NewJRMLogo.png",
      route: "/JamatRazaeMustafa",
      content:
        "Jamat Raza-e-Mustafa was established in 1920 by the Aala Hazrat Imam Ahmad Raza Khan. This Jamat is working under the spiritual guidance of Imam Ahmad Raza Khan, Huzoor Mufti-e-Azam-e-Hind and Huzoor Taajush Shari'ah.At present, Allama Mufti Muhammad Asjad Raza Khan Qadri Hafidahullahu Ta'ala / حفظہ اللہ تعالیٰ is leading the Jamat.",
    },
  ];
  return (
    <div className="serviceComponent">
      <p>AREAS WHAT WE SERVE</p>
      <p>OUR DEPARTMENT'S</p>
      <Grid container spacing={0}>
        {items.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </Grid>
    </div>
  );
}

function Item(props) {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      lg={4}
      className="d-flex justify-content-center"
      style={{ width: "100vw" }}
    >
      <Link
        style={{ decoration: "none" }}
        className="link"
        to={props.item.route}
      >
        <div className="serviceCard">
          <img
            src={window.location.origin + props.item.img}
            className="deptImg"
            alt="alt"
          />
          <div>
            <h3 style={{color:'black'}} className="link">{props.item.head}</h3>
            <p className="serviceCompoCardTxt">{props.item.content}</p>
          </div>
        </div>
      </Link>
    </Grid>
  );
}

