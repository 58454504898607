import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Redirect } from "react-router";
import HomePage from "../Home_Page/Home_Page.jsx";
import AboutUs from "../AboutUs_Page/Aboutus";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import ContactUs from "../Contact_Us/ContactUs";
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import RefundPolicy from '../RefundPolicy/RefundPolicy'
import Department from "../Department/Department";
import CentreForBookCompilation from "../Department/CentreForBookCompilation";
import CentreForIslamicJurisprudence from "../Department/CentreForIslamicJurisprudence";
import CentreForJudicialVerdict from '../Department/CentreForJudicialVerdict';
import CenterOfIslamicStudiesJamiaturRaza from '../Department/CenterOfIslamicStudiesJamiaturRaza';
import CentreOfResearchAndTraining from '../Department/CentreOfResearchAndTraining';
import DepartmentOfPrintingAndPublishing from '../Department/DepartmentOfPrintingAndPublishing';
import DepartmentOfReligiousJournalism from '../Department/DepartmentOfReligiousJournalism';
import MarkaziDarulIfta from '../Department/MarkaziDarulIfta';
import MarkaziDarulQaza from '../Department/MarkaziDarulQaza';
import MarkaziMoonSightingCommittee from '../Department/MarkaziMoonSightingCommittee';
import MonthlySunniDuniya from '../Department/MonthlySunniDuniya';
import JamatRazaeMustafa from '../Department/JamatRazaeMustafa';
import ShareeCouncilOfIndia from '../Department/ShareeCouncilOfIndia';
import Donation from "../Donation/Donation";
import GridBlog from "../Blog/Gridblog/Gridblog";
import UIElements from "../Pages_Menu/UI_Elements/UiElements";
import { Navbar } from "react-bootstrap";
import Reviews from "../Reviews/Reviews";
import ComingSoon from "../ComingSoon/ComingSoon";
import News from '../News/News';
import ScrollToTop from "./ScrollToTop";
import Projects from "../Projects/Projects";
import Gallery from "../Gallery/Gallery";
import Career from "../Career_Page/Career.jsx";
import Books from "../Book/Books";
export default function LandingPage() {
  return (
    <BrowserRouter onUpdate={() => window.scrollTo(0, 0)}>
      <ScrollToTop>
        <Header />
        <Switch>
          {/* <Route  path={''} component={Page1}/>  */}
          <Route path={"/home"} component={HomePage} />
          <Route path={"/about"} component={AboutUs} />
          <Route path={"/Department"} component={Department} />
          <Route path={"/Donation"} component={Donation} />
          <Route path={"/news"} component={News} />
          <Route path={"/projects"} component={Projects} />
          <Route path={"/gallery"} component={Gallery} />
          <Route
            path={"/CentreForBookCompilation"}
            component={CentreForBookCompilation}
          />
          <Route
            path={"/CentreForIslamicJurisprudence"}
            component={CentreForIslamicJurisprudence}
          />
          <Route path={'/CentreForJudicialVerdict'}
            component={CentreForJudicialVerdict}

          />
          <Route path={'/CenterOfIslamicStudiesJamiaturRaza'}
            component={CenterOfIslamicStudiesJamiaturRaza}
          />
          <Route path={'/CentreOfResearchAndTraining'}
            component={CentreOfResearchAndTraining}
          />
          <Route path={'/DepartmentOfPrintingAndPublishing'}
            component={DepartmentOfPrintingAndPublishing}
          />
          <Route path={'/DepartmentOfReligiousJournalism'}
            component={DepartmentOfReligiousJournalism}
          />
          <Route path={'/MarkaziDarulIfta'}
            component={MarkaziDarulIfta}
          />
          <Route path={'/MarkaziDarulQaza'}
            component={MarkaziDarulQaza}
          />
          <Route path={'/MarkaziMoonSightingCommittee'}
            component={MarkaziMoonSightingCommittee}
          />
          <Route path={'/MonthlySunniDuniya'}
            component={MonthlySunniDuniya}
          />
          <Route path={'/JamatRazaeMustafa'}
            component={JamatRazaeMustafa}
          />
          <Route path={'/ShareeCouncilOfIndia'}
            component={ShareeCouncilOfIndia}
          />
          <Route path={'/Career'}
            component={Career}
          />
          <Route path={"/Books"}
            component={Books}
          />
          <Route path={"/ContactUs"} component={ContactUs} />
          <Route path={'/TermsAndConditions'}

            component={TermsAndConditions}
          />
          <Route path={'/PrivacyPolicy'}

            component={PrivacyPolicy}
          />
          <Route path={'/RefundPolicy'}

            component={RefundPolicy}
          />
          <Route path={"/GridBlog"} component={GridBlog} />
          <Route path={"/UIElements"} component={UIElements} />
          <Route path={"/reviews"} component={Reviews} />
          <Route path={"/comingSoon"} component={ComingSoon} />

          <Redirect from="" to="/home" />
        </Switch>
        <Footer />
      </ScrollToTop>
    </BrowserRouter>
  );
}
