import React from 'react'
import './ContactUS.css';
import StayCurrentPortraitIcon from '@material-ui/icons/StayCurrentPortrait';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import EmailIcon from '@material-ui/icons/Email';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
export default function ContactUs() {
    return (
        <div>
            <nav className="bread-crumbs">
                <div>
                    <div className="row">
                        <div className="col-12">
                            <ul className="bread-crumbs-list">
                                <li>
                                    <a href="index.html" className="hoverNavHead">Home</a>
                                    <span><ChevronRightIcon className="material-icons md-18" />	</span>

                                </li>
                                <li><a href="ContactUs" className="hoverNavHead">Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            <div className="contactUsHeader pl-lg-5 pr-lg-5">
                <div className="contactTouch d-flex justify-content-center">
                    WE ARE ALWAYS IN TOUCH
            </div>
                <div className="contact_US d-flex justify-content-center">
                    Contact Us
            </div>

                <div style={{ display: 'flex', marginTop: '40px' }} className="row">
                    <div className="col-12 col-lg-5 ml-0" style={{ backgroundColor: '#F0F4F8', paddingLeft: '30px', borderRadius: '5px' }}>
                        <div style={{ fontSize: '28px', fontWeight: 'bold', marginTop: '20px' }}> Get In Touch</div>
                        <div style={{ paddingTop: '27px', display: 'flex' }}>
                            <LocationOnIcon style={{ color: '#2CA3F0', fontSize: '28px' }} />
                            <div className="em_ad">82, Dargah-e Aala Hazrat,
                            Mohalla Saudagaran,
                            Bareilly Sharif, Uttar Pradesh
                            India
POSTAL CODE - 243003</div>
                        </div>

                        <div style={{ paddingTop: '27px', display: 'flex' }}>
                            <StayCurrentPortraitIcon style={{ color: '#2CA3F0', fontSize: '28px' }} />
                            <div className="em_ad">+91 9897007120 <br /> +91 581 2458543 </div>
                        </div>


                        <div style={{ paddingTop: '27px', display: 'flex', paddingBottom: '80px' }}>
                            <EmailIcon style={{ color: '#2CA3F0', fontSize: '28px' }} />
                            <div className="em_ad">ContactUs@ImamAhmadRazaTrust.Org
                    {/* <br /> info@example.com */}
                            </div>
                        </div>

                    </div>
                    <div className="col-12 col-sm-7" style={{ paddingLeft: '5vw' }}>
                        <div style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '40px' }}>
                            Contact Form
                     </div>

                        <div className="row mb-5" style={{ display: 'flex' }}>
                            <div className="form-field1 col-12 col-sm-4" style={{ position: 'relative' }}>
                                <input className="form-control contactInp" type="text" placeholder="Your First Name" />
                            </div>
                            <div className="form-field1 col-12 col-sm-4" style={{ position: 'relative' }}>
                                <input className="form-control contactInp" type="text" placeholder="Your Middle Name" />
                            </div>
                            <div className="form-field1 col-12 col-sm-4" style={{ position: 'relative' }}>
                                <input className="form-control contactInp" type="text" placeholder="Your Last Name" />
                            </div>


                        </div>



                        <div className="form-field mb-5" style={{ position: 'relative' }}>
                            <textarea className="form-control" id="exampleFormControlTextarea1" cols="26" rows="6"
                                style={{ overflow: 'hidden', overflowWrap: 'break-word', height: '180px' }} placeholder="Your Message" />
                        </div>

                        <div>
                            <button className="contactBtn">
                                Send Message
                        </button>
                        </div>


                    </div>

                </div>


            </div>
            <iframe
                title="title"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3510.8978852634395!2d79.40638411549136!3d28.361936703026224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39a00730d4cad619%3A0xad84ad613cb0e596!2sDargah%20E%20Aalahazrat%20Bareilly%20Sharif!5e0!3m2!1sen!2sin!4v1618048717162!5m2!1sen!2sin"
                height="450"
                style={{ border: '0', marginTop: '40px', width: '98.5vw' }}
                frameborder="0"
                allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>

        </div>
    )
}
